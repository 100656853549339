import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Share,
  Twitter,
  YouTube,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import { default as Logo } from '../../assets/icons/otra-vida-tattoo_logo.png';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { default as XingIcon } from '../../assets/icons/xing_logo.png';
import { default as GoogleIcon } from '../../assets/icons/google.png';
import * as _ from 'lodash';

const Header = () => {
  const { currentUser, signOut, locations } = useAuth();
  const [addBg, setAddBg] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [onExit, setOnExit] = useState(true);
  const [socialMedia, setSocialMedia] = useState(undefined);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let windowPosition = window.scrollY;
    let windowWidth = window.innerWidth;

    document.addEventListener('scroll', (e) => {
      windowPosition = window.scrollY;
      if (windowPosition > 50) {
        setAddBg(true);
      } else {
        setAddBg(false);
      }
    });

    window.addEventListener('resize', (e) => {
      windowWidth = window.innerWidth;
      if (windowWidth < 978) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    window.addEventListener('click', (e) => {
      let popup = document.getElementById('social-popup'),
          targetEl = e.target;

      if (targetEl !== popup) {
        setIsSocialOpen(false);
      }
    });

    if (windowWidth < 978) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (windowPosition > 70) {
      setAddBg(true);
    } else {
      setAddBg(false);
    }

  }, []);

  useEffect(() => {
      if (!socialMedia && locations) {
        const socialsArr = [];
        locations.forEach((l) => {
          if (l.socialMedia.length) {
            socialsArr.push({
              locationName: l.locationName,
              socialMedia: l.socialMedia
            });
          }
        });
        setSocialMedia(socialsArr);
      }
  }, [socialMedia]);

  useEffect(() => {
    if (locations) {
      const socialsArr = [];
      locations.forEach((l) => {
        if (l.socialMedia.length) {
          socialsArr.push({
            locationName: l.locationName,
            socialMedia: l.socialMedia
          });
        }
      });
      setSocialMedia(socialsArr);
    }
  }, [locations])

  const menuVariants = {
    hide: {
      x: '-100vw',
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration: 0.5,
      },
    },
    exit: {
      x: '-100vw',
      opacity: 0,
      transition: {
        ease: 'easeInOut',
        duration: 0.5,
      },
    },
  };

  return (
    <header
      className={classnames({
        'with-background': addBg,
        mobile: isMobile,
        open: isOpen,
      })}
    >
      <img
        src={Logo}
        alt="Otra Vida Tattoo & Piercing Stuttgart Logo"
        className="logo"
        onClick={() => history.push('/')}
      />
      <nav className="navigation">
        <ul key="header-animation">
          {isMobile && (isOpen || !onExit) && (
            <>
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                <NavLink
                  exact={true}
                  to="/"
                  activeClassName="selected"
                >
                  Home
                </NavLink>
              </motion.li>
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0.2 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                <NavLink to="/team" activeClassName="selected">
                  Team
                </NavLink>
              </motion.li>
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0.4 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                <NavLink to="/gallery" activeClassName="selected">
                  Galerie
                </NavLink>
              </motion.li>
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0.6 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                <NavLink to="/contact" activeClassName="selected">
                  Kontakt & Anfahrt
                </NavLink>
              </motion.li>
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0.8 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                <NavLink to="/news" activeClassName="selected">
                  News
                </NavLink>
              </motion.li>
              {currentUser && (
                <motion.li
                  variants={menuVariants}
                  initial="hide"
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 1 },
                  }}
                  exit="exit"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    if (isOpen) {
                      setOnExit(false);
                      setTimeout(() => {
                        setOnExit(true);
                      }, 300);
                    }
                  }}
                >
                  <NavLink to="/settings" activeClassName="selected">
                    Einstellungen
                  </NavLink>
                </motion.li>
              )}
              <motion.li
                variants={menuVariants}
                initial="hide"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.5, delay: 1.2 },
                }}
                exit="exit"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    setOnExit(false);
                    setTimeout(() => {
                      setOnExit(true);
                    }, 300);
                  }
                }}
              >
                {currentUser ? (
                  <p onClick={signOut}>Logout</p>
                ) : (
                  <NavLink to="/login" activeClassName="selected">
                    Login
                  </NavLink>
                )}
              </motion.li>
            </>
          )}
          {!isMobile && (
            <>
              <li>
                <NavLink
                  exact={true}
                  to="/"
                  activeClassName="selected"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/team" activeClassName="selected">
                  Team
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" activeClassName="selected">
                  Galerie
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="selected">
                  Kontakt & Anfahrt
                </NavLink>
              </li>
              <li>
                <NavLink to="/news" activeClassName="selected">
                  News
                </NavLink>
              </li>
              {currentUser && (
                <li>
                  <NavLink to="/settings" activeClassName="selected">
                    Einstellungen
                  </NavLink>
                </li>
              )}
              <li>
                {currentUser ? (
                  <p onClick={signOut}>Logout</p>
                ) : (
                  <NavLink to="/login" activeClassName="selected">
                    Login
                  </NavLink>
                )}
              </li>
            </>
          )}
        </ul>
      </nav>
      {socialMedia && socialMedia.length > 0 && (
        <div
          className="socials"
          onClick={() => setIsSocialOpen(!isSocialOpen)}
        >
          <div className="flex-box align-center">
            <div className='socials-placeholder' id="social-popup"/>
            <Share style={{ marginRight: '1rem', pointerEvent: "none" }}/>
            <p style={{pointerEvent: "none"}} >Folgen & bewerten</p>
          </div>
          <div
            className={classnames('popup flex-box flex-column', {
              open: isSocialOpen,
            })}
          >
            {_.map(socialMedia, (location, key) => {
              return (
                <div className="flex-box flex-column" key={key}>
                  {location.socialMedia.length > 0 && <p>{location.locationName}</p>}
                  <div className="social-links">
                    {_.map(location.socialMedia, (media, index) => {
                      if (media.name.toLowerCase() === 'facebook') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Bewerte uns auf ' + media.name
                            }
                          >
                            <Facebook
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'instagram') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <Instagram
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'pinterest') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <Pinterest
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'twitter') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <Twitter
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'linkedin') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <LinkedIn
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'youtube') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <YouTube
                              onClick={() => window.open(media.url)}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'xing') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Besuche uns auf ' + media.name
                            }
                          >
                            <img
                              src={XingIcon}
                              onClick={() => window.open(media.url)}
                              alt={media.name}
                            />
                          </div>
                        );
                      }
                      if (media.name.toLowerCase() === 'google') {
                        return (
                          <div
                            key={index}
                            className="social-icon"
                            data-text={
                              'Bewerte uns auf ' + media.name
                            }
                          >
                            <img
                              className="google-icon"
                              src={GoogleIcon}
                              onClick={() => window.open(media.url)}
                              alt={media.name}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isMobile && (
        <button
          className="btn-secondary"
          onClick={() => {
            setIsOpen(!isOpen);
            if (isOpen) {
              setOnExit(false);
              setTimeout(() => {
                setOnExit(true);
              }, 300);
            }
          }}
        >
          {isOpen ? 'Schließen' : 'Menü'}
        </button>
      )}
    </header>
  );
};

export default Header;
