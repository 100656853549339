import tattooImg from '../assets/images/otra-vida-tattoo_tattoo.webp';
import piercingImg from '../assets/images/otra-vida-tattoo_piercing.webp';
import { default as ConsultingIcon } from '../assets/icons/consulting.png';
import { default as BeSureIcon } from '../assets/icons/be-sure.png';
import { default as HygieneIcon } from '../assets/icons/hygiene.png';
import { default as ArtistIcon } from '../assets/icons/tattoo-artist.png';
import { default as FemalePiercing } from '../assets/icons/female-piercing.png';

export const services = [
  {
    title: 'Tattoo',
    subTitle: 'Professionelle Tattoos zu fairen Preisen',
    description:
      'Bei Otra Vida Stuttgart legen wir Wert auf die Zufriedenheit aller Kunden und Kundinnen. Deshalb arbeiten bei uns nur Profis mit jahrelanger Erfahrung im Tätowieren. Bei uns kommt jeder Geschmack auf seine Kosten. Unsere Mitarbeiter beherrschen zahlreiche Stile und freuen sich auf deine Ideen. Kontaktiere uns für ein persönliches Beratungsgespräch.',
    btnName: 'Lern unser Team kennen',
    linkTo: '/team',
    image: tattooImg,
    sectionName: 'tattoo-section',
  },
  {
    title: 'Piercing',
    subTitle: 'Piercings, die dich zum Strahlen bringen',
    description:
      'Egal, ob Piercings im Gesicht oder am Körper – Wir bieten eine große Auswahl an edlen Piercings. Unsere zertifizierten Piercer helfen dir gerne bei der Auswahl deines Wunschpiercings. Durch sorgfältige Arbeit und höchste Hygienestandards sorgen wir dafür, dass du dich rundum wohlfühlst. Kontaktiere uns für ein persönliches Beratungsgespräch.',
    btnName: 'Lern unser Team kennen',
    linkTo: '/team',
    image: piercingImg,
    sectionName: 'piercing-section',
  },
];

export const principles = [
  {
    title: 'Persönliche Beratung ist ein Muss',
    description:
      'Wir wollen dich kennenlernen, damit wir für dich das bestmögliche Tattoo oder Piercing planen können. Dabei legen wir Wert auf einen offenen Austausch, damit es keine Missverständnisse gibt und du 100% zufrieden bist.',
    icon: (
      <img
        src={ConsultingIcon}
        alt="Persönliche Beratung ist ein Muss"
      />
    ),
  },
  {
    title: 'Du musst dir sicher sein',
    description:
      'Wir quatschen dich nicht in etwas hinein, das du nicht willst. Deshalb bitten wir dich, deine Tattoo-, oder Piercing-Idee gut zu überdenken. Wir lassen dir genügend Zeit, dir alles durch den Kopf gehen zu lassen, bevor du deine endgültige Entscheidung triffst.',
    icon: <img src={BeSureIcon} alt="Du musst dir sicher sein" />,
  },
  {
    title: 'Höchste Hygienestandards',
    description:
      'Dein körperliches Wohlbefinden steht bei uns an erster Stelle. Deshalb achten wir streng auf die Einhaltung der Corona-Vorschriften und verwenden nur steriles Arbeitsmaterial, das für jede Sitzung erneuert wird. Wir sind eine vom Gesundheitsamt anerkannte Teststelle und können den Antigen-Schnelltest vor Ort durchführen, inklusive Bescheinigung.',
    icon: <img src={HygieneIcon} alt="Höchste Hygienestandards" />,
  },
  {
    title: 'Arbeit von Profis',
    description:
      'Wir wollen dein Vertrauen durch professionelle und saubere Arbeit gewinnen. Deshalb wird bei uns jeder Arbeitsschritt von der Beratung und Planung, über die Tattoo-Sitzung, bis hin zur Pflege danach, mit größtmöglicher Sorgfalt ausgeführt.',
    icon: <img src={ArtistIcon} alt="Arbeit von Profis" />,
  },
  {
    title: 'Piercings von Frauen für Frauen',
    description:
      'Du fühlst Dich als Frau wohler, wenn dein Tattoo oder Piercing von einer Frau gestochen wird? Kein Problem, bei uns stehen zu jeder Zeit weibliche Tätowierinnen und Piercerinnen für Dich zur Verfügung.',
    icon: (
      <img src={FemalePiercing} alt="Piercings von Frauen für Frauen" />
    ),
  },
];
