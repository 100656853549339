import React, { useEffect, useState } from 'react';
import {default as Logo} from '../../assets/icons/otra-vida-tattoo_logo.png';
import {default as CallIcon} from '../../assets/icons/call_icon.png';
import {default as EmailIcon} from '../../assets/icons/email_icon.png';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import {default as ArrowUpIcon} from '../../assets/icons/arrow-up.png';
import anime from 'animejs';
import classnames from 'classnames';
import { Favorite, LocationOn } from '@material-ui/icons';
import { useAuth } from '../../contexts/auth.context';

const FooterComponent = () => {
    const [showToTop, setShowToTop] = useState(false);
    const {locations} = useAuth();

    useEffect(() => {
        const unsubscribe = () => {
            document.addEventListener('scroll', () => {
                const windowY = window.scrollY;

                if (windowY > 1200) {
                    setShowToTop(true)
                } else {
                    setShowToTop(false)
                }
            });
        }

        return unsubscribe;
    }, []);

    return <footer>
        <div className="top">
            <div className="address-box">
                <img src={Logo} alt="Logo"/>
                <p className="company-name">Otra Vida<br/>Tattoo & Piercing</p>
            </div>
            <div className="locations-container">
                {locations && locations.map((info, key) => {
                    return <div className="contact-box" key={key}>
                        {info && info.locationName && <div className="location-footer">
                            <LocationOn /> {info.locationName}
                        </div>}
                        <div className="contact" key={key}>
                            <p>Inh: {info.owner.name} {info.owner.surname}</p>
                        </div>
                        {info && info.contact ? _.map(info.contact, (contact, key) => {
                            if (!_.isNil(contact) && contact) {
                                if (key === 'email') {
                                    return <div className="contact" key={key}>
                                        <img src={EmailIcon} alt="E-Mail - icon"/>
                                        <p><a href={"mailto:"+contact}>{contact}</a></p>
                                    </div>
                                }
                                if (key === 'phone') {
                                    return <div className="contact" key={key}>
                                        <img src={CallIcon} alt="Call - icon"/>
                                        <p><a href={"tel:"+contact}>{contact}</a></p>
                                    </div>
                                }
                            } else {
                                return <div className="contact" key={key}>
                                    <p>😳 {key === 'phone' ? 'Telefon' : 'E-Mail'} nicht vorhanden</p>
                                </div>
                            }
                        }) : <div className="information-about-login">
                            <h2>😳 Keine Kontaktdaten vorhanden</h2>
                        </div>}
                    </div>
                })}
            </div>
            <div className="navigation-box">
                <NavLink activeClassName="selected" to="/">Home</NavLink>
                <NavLink activeClassName="selected" to="/team">Team</NavLink>
                <NavLink activeClassName="selected" to="/gallery">Galerie</NavLink>
                <NavLink activeClassName="selected" to="/contact">Kontakt & Anfahrt</NavLink>
                <NavLink activeClassName="selected" to="/news">News</NavLink>
            </div>
        </div>
        <div className="bottom">
            <NavLink activeClassName="selected" to="/impressum">Impressum</NavLink>
            <NavLink activeClassName="selected" to="/privacy">Datenschutz</NavLink>
            <img src={ArrowUpIcon} className={classnames("go-top", {'show': showToTop})} alt="go-to-top" onClick={() => {
                const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                anime({
                    targets: scrollElement,
                    scrollTop: 0,
                    easing: 'cubicBezier(.3,.3,.56,1)',
                    duration: 3000
                });
            }}/>
            <p className="flex-box creators" onClick={() => window.open('https://www.instagram.com/trippy.pixels.team/')}>Made with <Favorite className="heart-beat-anim" /> from Trippy Pixels</p>
        </div>
    </footer>
}

export default FooterComponent;