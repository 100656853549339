import {firestore, storage} from '../firebase';

export const addNewPost = async (postData) => {
    try {
        if (postData.image) {
            await storage.ref('posts-images/'+postData.image.name).put(postData.image).then(val => {
                val.ref.getDownloadURL().then(imageUrl => {
                    firestore.collection('posts').doc(postData.title.split(' ').join('-').toLowerCase()).set({
                        title: postData.title,
                        description: postData.description,
                        createdAt: new Date(),
                        tags: postData.tags,
                        imageUrl: imageUrl,
                        imageName: postData.image.name
                    }).then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news'));
                })
            });
        }
    } catch (err) {
        console.log(err)
        alert(err.message);
    }
}

export const getAllPosts = async () => {
    try {
        return await firestore.collection('posts').get().then(snap => {
            const posts = [];
            snap.forEach(doc => {
                posts.push({...doc.data(), createdAt: doc.data().createdAt.toDate()})
            })
            return posts;
        })
    } catch (err) {
        alert(err.message);
    }
}
export const getPost = async (postName) => {
    try {
        return await firestore.collection('posts').doc(postName).get().then(snap => {
            return {...snap.data(), createdAt: snap.data().createdAt.toDate()}
        })
    } catch (err) {
        alert(err.message);
    }
}
export const deletePost = async (postName, imageName) => {
    try {
        return await firestore.collection('posts').doc(postName).delete().then(() => {
            storage.ref('posts-images/').child(imageName).delete().then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news')).catch(err => console.log('Image does not exist'));
        });
    } catch (err) {
        alert(err.message);
    }
}

export const updatePost = async (postData, oldData) => {
    const oldDataTitle = oldData.oldPostId ? oldData.oldPostId.split(' ').join('-').toLowerCase() : false;
    const newDataTitle = postData.title.split(' ').join('-').toLowerCase();

    try {
        if (oldData.deletePost) {
            await firestore.collection('posts').doc(oldDataTitle).delete().then(() => {
                if (oldData.imageChanged) {
                    storage.ref('posts-images/').child(oldData.oldPostImageName).delete().then(() => {
                        storage.ref('posts-images/'+postData.image.name).put(postData.image).then(val => {
                            val.ref.getDownloadURL().then(imageUrl => {
                                firestore.collection('posts').doc(newDataTitle).set({
                                    'title': postData.title,
                                    'description': postData.description,
                                    'tags': postData.tags,
                                    'imageUrl': imageUrl,
                                    'imageName': postData.image.name,
                                    'createdAt': new Date()
                                }).then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news'));
                            })
                        })
                    })
                } else {
                    firestore.collection('posts').doc(newDataTitle).set({
                        ...postData,
                        'createdAt': new Date()
                    }).then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news'))
                }
            });
        } else {
            if (oldData.imageChanged) {
                storage.ref('posts-images/').child(oldData.oldPostImageName).delete().then(() => {
                    storage.ref('posts-images/'+postData.image.name).put(postData.image).then(val => {
                        val.ref.getDownloadURL().then(imageUrl => {
                            firestore.collection('posts').doc(newDataTitle).update({
                                'title': postData.title,
                                'description': postData.description,
                                'tags': postData.tags,
                                'imageUrl': imageUrl,
                                'imageName': postData.image.name,
                                'createdAt': new Date()
                            }).then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news'))
                        })
                    })
                })
            } else {
                firestore.collection('posts').doc(newDataTitle).update({
                    ...postData,
                    'createdAt': new Date()
                }).then(() => window.location.replace(process.env.REACT_APP_DOMAIN_URL+'news'))
            }
        }
    } catch (err) {
        console.log(err)
        alert(err.message);
    }
}