import * as _ from 'lodash';
import { firestore } from './firebase';
import Geocode from "react-geocode";

export const sendEmail = async (data) => {
  if (!_.isEmpty(data)) {
    try {
      await firestore
        .collection('contacts')
        .doc(data.email.replace(/(\.|@)/g, '-').toLowerCase())
        .collection('messages')
        .add({
          ...data,
          contactId: data.email.replace(/(\.|@)/g, '-').toLowerCase(),
          createdAt: new Date(),
        });
    } catch (err) {
      console.error(err);
    }
  }
};
export const getCoordinates = async (address) => {
  if (
    address.street &&
    address['street-number'] &&
    address['post-code'] &&
    address.city
  ) {
    const fullAddress =
      address.street +
      address['street-number'] +
      ',' +
      address['post-code'] +
      address.city;

    try {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_TOKEN);
      Geocode.setLanguage("de");
      return await Geocode.fromAddress(fullAddress).then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          return {lat, lng};
      });
    } catch (err) {
      alert(err.message);
    }
  }
};
