import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { sendEmail } from '../../utils/contact';
import { ArrowForwardIos, LocationOn } from '@material-ui/icons';
import { default as CallIcon } from '../../assets/icons/call_icon.png';
import { default as EmailIcon } from '../../assets/icons/email_icon.png';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import GoogleMapReact from 'google-map-react';
import { Validate } from '../../utils/validation';
import * as _ from 'lodash';
import { useAuth } from '../../contexts/auth.context';

const MarkerComponent = () => <LocationOn className='location-icon' />;

const ContactComponent = () => {
  const { locations } = useAuth();
  const { location } = useParams();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { employeeName } = useParams();
  const [empTerm, setEmpTerm] = useState(false);
  const [resMsg, setResMsg] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const [validEmail, setValidEmail] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validMessage, setValidMessage] = useState(true);

  const [activeLocation, setActiveLocation] = useState(
    location ? location : ''
  );

  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (employeeName) {
      setEmpTerm(
        employeeName
          .split('-')
          .join(' ')
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
      );
    }
    if (locations && locations[0] && !activeLocation) {
      setActiveLocation(locations[0].locationName);
    }
    // eslint-disable-next-line
  }, [locations]);

  const sorter = {
    montag: 1,
    dienstag: 2,
    mittwoch: 3,
    donnerstag: 4,
    freitag: 5,
    samstag: 6,
    sonntag: 7
  };

  const sortDays = (arr) => {
    return arr.sort((a, b) => {
      return sorter[a.toLowerCase()] - sorter[b.toLowerCase()];
    });
  };

  const handleSubmit = () => {
    if (name === '' && email === '' && message === '') {
      setValidName(false);
      setValidEmail(false);
      setValidMessage(false);
      setErrMsg('Bitte fülle alle mit Sternchen markierten Pflichtfelder aus.');
    } else if (!Validate('name', name)) {
      setValidName(false);
      setErrMsg('Bitte gib deinen Vor- und Nachname ein.');
    } else if (!Validate('email', email)) {
      setValidName(true);
      setValidEmail(false);
      setErrMsg('Du hast eine ungültige E-Mail eingegeben.');
    } else if (message.length < 20) {
      setValidEmail(true);
      setValidMessage(false);
      setErrMsg('Deine Nachricht sollte mindestens 20 Zeichen enthalten.');
    } else if (!isChecked) {
      setValidMessage(true);
      setErrMsg('Bitte akzeptiere unsere Datenschutzerklärung.');
    } else {
      const data = {
        name,
        email,
        message,
        acceptedTerms: isChecked,
        employeeName: empTerm || false,
        locationEmail:
          locations.find((e) => e.locationName === activeLocation).contact
            .email || false
      };
      sendEmail(data).then(() => {
        setResMsg('Deine Nachricht wurde erfolgreich gesendet.');
        handleReset();
        setTimeout(() => {
          setResMsg('');
        }, 4000);
      });
    }
  };

  const handleReset = () => {
    document.querySelectorAll('input, textarea').forEach((el) => {
      el.value = '';
    });
    setName('');
    setEmail('');
    setMessage('');
    setErrMsg('');
    setValidName(true);
    setValidEmail(true);
    setValidMessage(true);
    setIsChecked(false);
  };

  return (
    <section id='contact'>
      <Helmet>
        <meta
          name='description'
          content='Dein Kontakt für professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte'
        />
        <meta
          name='keywords'
          content='Tattoo, Piercing, Stuttgart, Kontakt, Kontaktformular, Termine, E-Mail, Anruf, Instagram, Stuttgart-Mitte, City, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort'
        />
        <meta
          property='og:title'
          content='Kontakt & Anfahrt – Otra Vida Tattoo & Piercing Stuttgart'
        />
        <meta
          property='og:description'
          content='Dein Kontakt für professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte'
        />
        <meta
          property='og:image'
          content='/assets/images/otra-vida_fb-image.png'
        />
        <title>Kontakt & Anfahrt – Otra Vida Tattoo & Piercing Stuttgart</title>
      </Helmet>
      <div className='section-header'>
        <h1>Kontakt & Anfahrt</h1>
      </div>
      <div className='section-sub-header'>
        {_.map(locations, (location, index) => {
          return (
            <div
              className={classnames('location-field', {
                active: activeLocation === location.locationName
              })}
              onClick={(e) => {
                setActiveLocation(location.locationName);
              }}
              key={index}
            >
              <LocationOn />
              <span>{location.locationName}</span>
              <ArrowForwardIos
                className={classnames('collapsed-icon', {
                  active: activeLocation === location.locationName
                })}
              />
            </div>
          );
        })}
      </div>
      <div className='contact-locations'>
        {_.map(locations, (location, index) => {
          return (
            location.locationName === activeLocation && (
              <div key={index}>
                <p className={classnames('msg', { open: resMsg })}>{resMsg}</p>
                <div className='section-body'>
                  <div className='contact-box'>
                    <h2>Wir freuen uns auf deine Anfrage</h2>
                    {errMsg && <p className='err-msg'>{errMsg}</p>}
                    <div
                      className={classnames('contact-field', {
                        'has-error': !validName
                      })}
                    >
                      <p>Name *</p>
                      <input
                        type='text'
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div
                      className={classnames('contact-field', {
                        'has-error': !validEmail
                      })}
                    >
                      <p>E-Mail *</p>
                      <input
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div
                      className={classnames('contact-field', {
                        'has-error': !validMessage
                      })}
                    >
                      <p>Deine Nachricht *</p>
                      <textarea
                        type='text'
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className='contact-field flex-box'>
                      <div
                        className={classnames('checkbox', {
                          checked: isChecked
                        })}
                        onClick={() => setIsChecked(!isChecked)}
                      >
                        <span />
                      </div>
                      <p>
                        Du erklärst dich damit einverstanden, dass deine Daten
                        zur Bearbeitung deines Anliegens verwendet werden. Mehr
                        Informationen findest du in unserer{' '}
                        <Link to={'/privacy'}>Datenschutzerklärung.</Link>
                      </p>
                    </div>
                    <button className='btn-primary' onClick={handleSubmit}>
                      Absenden
                    </button>
                  </div>
                  <div className='place-box'>
                    <h2>So findest du uns</h2>
                    <div className='map'>
                      {location && location.coordinates ? (
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_API_TOKEN
                          }}
                          defaultCenter={{
                            address: `${location.address['street-number']} ${location.address.street}, ${location.address.city}`,
                            lat: location.coordinates.lat,
                            lng: location.coordinates.lng
                          }}
                          defaultZoom={15}
                          options={{
                            controlSize: false,
                            disableDoubleClickZoom: true,
                            disableDefaultUI: true,
                            maxZoom: 17
                          }}
                        >
                          <MarkerComponent
                            lat={location.coordinates.lat}
                            lng={location.coordinates.lng}
                          />
                        </GoogleMapReact>
                      ) : (
                        <h4>😳 Keine Adresse vorhanden</h4>
                      )}
                    </div>
                    {location && location.coordinates && (
                      <a
                        className='flex-box'
                        href={`https://maps.google.com/maps?q=${
                          location.address.street +
                          ' ' +
                          location.address['street-number'] +
                          ', ' +
                          location.address['post-code'] +
                          ' ' +
                          location.address.city
                        }`}
                      >
                        <LocationOn />{' '}
                        <p>
                          {location.address.street +
                            ' ' +
                            location.address['street-number'] +
                            ', ' +
                            location.address['post-code'] +
                            ' ' +
                            location.address.city}
                        </p>
                      </a>
                    )}
                  </div>
                  {location &&
                    (location.contact.email || location.contact.phone) && (
                      <div className='info-box'>
                        <div className='contact'>
                          {location &&
                            (location.contact.email ||
                              location.contact.phone ||
                              (location.openHours &&
                                !_.isEmpty(location.openHours))) && (
                              <h2>Direkter Kontakt</h2>
                            )}
                          {location && location.contact && (
                            <ul>
                              {location.contact.phone && (
                                <li>
                                  <img src={CallIcon} alt='Call - icon' />{' '}
                                  <a href={'tel:' + location.contact.phone}>
                                    {location.contact.phone}
                                  </a>
                                </li>
                              )}
                              {location.contact.email && (
                                <li>
                                  <img src={EmailIcon} alt='E-Mail - icon' />{' '}
                                  <a href={'mailto:' + location.contact.email}>
                                    {location.contact.email}
                                  </a>
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                        <div className='open-hours'>
                          {location &&
                            location.openHours &&
                            !_.isEmpty(location.openHours) && (
                              <h2>Öffnungszeiten</h2>
                            )}
                          {location &&
                            location.openHours &&
                            !_.isEmpty(location.openHours) && (
                              <table>
                                <tbody>
                                  {_.map(location.openHours, (h, key) => {
                                    return (
                                      <tr key={key}>
                                        <td className='days'>
                                          {_.map(
                                            _.filter(
                                              Array.from(
                                                sortDays(Object.keys(h.days))
                                              ),
                                              (op) => h.days[op].index === key
                                            ),
                                            (v) => v.slice(0, 2)
                                          ).join(', ')}
                                        </td>
                                        {h.allDayTime && (
                                          <>
                                            <td className='hours'>
                                              {h.times.from} - {h.times.to}
                                            </td>
                                            <td></td>
                                          </>
                                        )}
                                        {!h.allDayTime && (
                                          <>
                                            <td className='hours'>
                                              {h.times.morning.from} -{' '}
                                              {h.times.morning.to}
                                            </td>
                                            <td className='hours'>
                                              {h.times.afternoon.from} -{' '}
                                              {h.times.afternoon.to}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </section>
  );
};

export default ContactComponent;
