import React, { useContext, useState } from 'react';
import { Component } from 'react';

const ModalContext = React.createContext();

export const useModal = () => {
    return useContext(ModalContext);
}

export const ModalProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState(null);
    const [data, setData] = useState(null);
    const [answer, setAnswer] = useState(null);

    function openModal (modalTitle, modalContent = Component, modalData) {
        if (!isOpen) {
            setIsOpen(true);
            setTitle(modalTitle);
            setContent(modalContent);
            setData(modalData)
            if (modalData === "input") {

            }
        } else {
            setIsOpen(false);
            setTitle('');
            setContent(null);
        }
    }

    function callback (modalAnswer = false) {
        setAnswer(modalAnswer);
        setIsOpen(false);
    }

    const value = {
        isOpen,
        title,
        content,
        answer,
        data,
        callback,
        openModal
    }
    return <ModalContext.Provider value={value}>
        {children}
    </ModalContext.Provider>
}