import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const SectionWidget = ({title, subTitle, description, btnName, linkTo, imgPath, sectionName, n}) => {
    const controls = useAnimation();
    const {ref, inView} = useInView({
        threshold: 0.5,
    });
    const history = useHistory();

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
    }, [controls, inView]);

    const imageVariants = {
        hidden: {
            x: n % 2 === 0 ? '60vw' : '-60vw',
            opacity: 0,
            transition: {
                duration: 2,
                ease: 'backIn'
            }
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            ease: 'backOut'
          }
        }
    }
    const titleVariants = {
        hidden: {
            y: n % 2 === 0 ? '40px' : '-40px',
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'backInOut'
            }
        },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
            ease: 'backInOut'
          }
        }
    }
    const infoVariants = {
        hidden: {
            x: n % 2 === 0 ? '-100px' : '100px',
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'backInOut'
            }
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            ease: 'backInOut'
          }
        }
    }
    const subtitleVariants = {
        hidden: {
            y: n % 2 === 0 ? '-20px' : '20px',
            opacity: 0,
            transition: {
                duration: 1,
                delay: 0.3,
                ease: 'easeInOut'
            }
        },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
            delay: 0.3,
            ease: 'easeInOut'
          }
        }
    }
    const descVariants = {
        hidden: {
            y: n % 2 === 0 ? '20px' : '-20px',
            opacity: 0,
            transition: {
                duration: 1,
                delay: .5,
                ease: 'easeInOut'
            }
        },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
            delay: .5,
            ease: 'easeInOut'
          }
        }
    }
    const linkVariants = {
        hidden: {
            x: n % 2 === 0 ? '-30px' : '30px',
            opacity: 0,
            transition: {
                duration: 1,
                delay: .5,
                ease: 'easeInOut'
            }
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            delay: .5,
            ease: 'easeInOut'
          }
        }
    }
    return <motion.section initial="hidden" exit="exit" id={sectionName} className="section-widget">
        <motion.img className="section-image" src={imgPath} animate={controls} initial="hidden" variants={imageVariants}/>
        <motion.h2 className="section-title" ref={ref} animate={controls} initial="hidden" variants={titleVariants}>{title}</motion.h2>
        <motion.div className="section-info" animate={controls} initial="hidden" variants={infoVariants}>
            <motion.h3 animate={controls} initial="hidden" variants={subtitleVariants} className="section-subtitle">{subTitle}</motion.h3>
            <motion.p animate={controls} initial="hidden" variants={descVariants} className="section-description">{description}</motion.p>
            <motion.button animate={controls} initial="hidden" variants={linkVariants} className="btn-secondary-dark" onClick={() => history.push(linkTo)} >{btnName}</motion.button>
        </motion.div>
    </motion.section>
}
export default SectionWidget;