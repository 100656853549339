import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import DropdownWidget from './dropdown.widged';
import TimePicker from 'react-time-picker';
import { DeleteOutline } from '@material-ui/icons';

const DayPickerWidget = ({data, callback, isEdited}) => {
    const [fromTo, setFromTo] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [days, setDays] = useState(
        {
            'Montag': {'value': false, index: null},
            'Dienstag': {'value': false, index: null},
            'Mittwoch': {'value': false, index: null},
            'Donnerstag': {'value': false, index: null},
            'Freitag': {'value': false, index: null},
            'Samstag': {'value': false, index: null},
            'Sonntag': {'value': false, index: null}
        }
    );

    useEffect(() => {
        if (_.isEmpty(fromTo)) {
            if (!_.isEmpty(data)) {
                setFromTo(data)
            }
        }
        // eslint-disable-next-line
    }, [])

    const handleAdd = (e, index) => {
        if (Object.keys(fromTo).length < 7) {
            setFromTo(old => {
                return {...old, [index]: {
                    days: [],
                    allDayTime: false,
                    title: 'Öffnungszeit',
                    times: {
                        morning: {
                            from: '',
                            to: ''
                        },
                        afternoon: {
                            from: '',
                            to: ''
                        }
                    }
                }}
            })
        } else {
            setErrMsg('Du kannst maximal 7 Öffnungszeiten hinzufügen.')
        }
    }

    useEffect(() => {
        callback(fromTo);
        // eslint-disable-next-line
    }, [fromTo])

    return <div className="day-picker">
        <button className={classnames("btn-primary", {'disabled': !isEdited})} onClick={e => {
            handleAdd(e, Object.keys(fromTo).length === 0 ? 0 : (parseInt(Object.keys(fromTo)[Object.keys(fromTo).length - 1]) + 1));
        }}>Hinzufügen</button>
        {errMsg && <p className="err-msg">{errMsg}</p>}
        <div className="days-cards">
            {!_.isEmpty(fromTo) && _.map(fromTo, (form, key) => {
                return <div className="daypicker-form" key={key}>
                    <div className="flex-box">
                        <DropdownWidget options={fromTo[Object.keys(fromTo)[0]] && !_.isEmpty(fromTo[Object.keys(fromTo)[0]].days) ? fromTo[Object.keys(fromTo)[0]].days : days} index={key} checked={values => {
                            if (values && values.index === key) {
                                setDays(values.checked)
                                form.days = values.checked;
                                _.map(fromTo, (f, i) => {
                                    setFromTo(old => {
                                        return {...old, [i]: {...old[i], days: values.checked}}
                                    })
                                })
                            }
                        }} withCheckbox={true}/>
                        <DeleteOutline className={classnames('icon-btn', {'disabled': !isEdited})} onClick={() => {
                            setFromTo(Object.keys(fromTo).reduce((a,b) => {
                                let rebuild = _.forEach(fromTo, (v, l) => {
                                    if (l === key) {
                                        _.map(v.days, (day) => {
                                            if (day.index === key) {
                                                day.index = null;
                                                day.value = false;
                                            }
                                        });
                                        delete fromTo[key];
                                        return v
                                    }
                                });
                                return {...rebuild}
                            }, {}));
                        }}/>
                    </div>
                    <div className="flex-box">
                        <div
                            className={classnames('checkbox', {'checked': form && form.allDayTime, 'disabled': !isEdited})}
                            onClick={() => setFromTo(old => ({...old, [key]: {...old[key], 'allDayTime': !old[key].allDayTime, 'times': old[key].allDayTime ? {morning: {from: '', to: ''}, afternoon: {from: '', to: ''}} : {from: '', to: ''}}}))}
                        >
                            <span/>
                        </div>
                        <p>Durchgehend geöffnet</p>
                    </div>
                    {form && !form.allDayTime && <div className="separate-day-time">
                        <label>
                            <p>Vormittag</p>
                            <div className="flex-box from-to-box">
                                <div className="flex-box flex-column">
                                    <p>Von</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'morning': {...old[key].times.morning, 'from': val}}}}))}
                                        value={form.times && form.times.morning ? form.times.morning.from : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                                <div className="flex-box flex-column">
                                    <p>Bis</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'morning': {...old[key].times.morning, 'to': val}}}}))}
                                        value={form.times && form.times.morning ? form.times.morning.to : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                            </div>
                        </label>
                        <label>
                            <p>Nachmittag</p>
                            <div className="flex-box from-to-box">
                                <div className="flex-box flex-column">
                                    <p>Von</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'afternoon': {...old[key].times.afternoon, 'from': val}}}}))}
                                        value={form.times && form.times.afternoon ? form.times.afternoon.from : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                                <div className="flex-box flex-column">
                                    <p>Bis</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'afternoon': {...old[key].times.afternoon, 'to': val}}}}))}
                                        value={form.times && form.times.afternoon ? form.times.afternoon.to : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                            </div>
                        </label>
                    </div>}
                    {form && form.allDayTime && <div className="all-day-time">
                        <p>Ganzer  Tag</p>
                        <div className="flex-box from-to-box">
                                <div className="flex-box flex-column">
                                    <p>Von</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'from': val}}}))}
                                        value={form.times ? form.times.from : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                                <div className="flex-box flex-column">
                                    <p>Bis</p>
                                    <TimePicker
                                        onChange={val => setFromTo(old => ({...old, [key]: {...old[key], 'times': {...old[key].times, 'to': val}}}))}
                                        value={form.times ? form.times.to : ''}
                                        clockIcon={null}
                                        closeClock={true}
                                    />
                                </div>
                            </div>
                    </div>}
                </div>
            })}
        </div>
    </div>
}

export default DayPickerWidget;