import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { CloseSharp } from '@material-ui/icons';

const TagInputWidget = ({hasTags, onInputChange}) => {
    const [tags, setTags] = useState([]);
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            setTags(old => [...old, e.target.value]);
            setTimeout(() => e.target.value = '');
        }
    }
    const handleRemoveTag = (tagName) => {
        setTags(old => old.filter(t => t !== tagName));
    }
    useEffect(() => {
        onInputChange(tags);
        // eslint-disable-next-line
    }, [tags])
    useEffect(() => {
        if (hasTags && _.isEmpty(tags)) {
            setTags(hasTags)
        }
        // eslint-disable-next-line
    }, [])

    return <div className="tag-input">
        {tags.length > 0 && <div className="tags-box">{_.map(tags, (tag, index) => <span key={index}>{tag} <CloseSharp className="close-icon" onClick={() => handleRemoveTag(tag)}/></span>)}</div>}
        <input onKeyDown={handleEnter} type="text" placeholder="Tag eintragen und mit ENTER bestätigen"/>
    </div>
}

export default TagInputWidget;