import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Header from './components/regular/header.component';
import HomeComponent from './components/regular/home.component';
import LoginComponent from './components/regular/login.component';
import NewsComponent from './components/regular/news.component';
import { AuthProvider } from './contexts/auth.context';
import PrivateRoute from './components/private.component';
import NewPostComponent from './components/private/new.post.component';
import PostComponent from './components/regular/post.component';
import FooterComponent from './components/regular/footer.component';
import GalleryComponent from './components/regular/gallery.component';
import NewEmployeeComponent from './components/private/new.employee.component';
import TeamComponent from './components/regular/team.component';
import { AnimatePresence } from 'framer-motion';
import SubFooterComponent from './components/regular/sub.footer.component';
import ModalComponent from './widgets/modal.widget';
import { ModalProvider } from './contexts/modal.context';
import SettingsComponent from './components/private/settings.component';
import ContactComponent from './components/regular/contact.component';
import { HelmetProvider } from 'react-helmet-async';
import './styles/styles.scss';
import ImpressumComponent from './components/law/impressum.component';
import PrivacyComponent from './components/law/privacy.components';
import CookieBot from 'react-cookiebot';

function App() {
  return (
    <HelmetProvider>
      <div id="otra-vida-webpage">
        {/* basename={'/websites/otra-vida'} for Router */}
        <CookieBot domainGroupId='a2df0b30-dfc0-4d75-a501-42e920ba0136'/>
        <Router>
          <AuthProvider>
            <ModalProvider>
              <Header />
              <Route
                render={({ location }) => {
                  return (
                    <AnimatePresence exitBeforeEnter>
                      <Switch location={location} key={location.key}>
                        <Route
                          exact
                          path="/"
                          component={HomeComponent}
                        />
                        <Route
                          exact
                          path="/login"
                          component={LoginComponent}
                        />
                        <Route
                          exact
                          path="/gallery"
                          component={GalleryComponent}
                        />
                        <Route
                          exact
                          path="/news"
                          component={NewsComponent}
                        />
                        <Route
                          exact
                          path="/team"
                          component={TeamComponent}
                        />
                        <Route
                          exact
                          path="/news/:postTitle"
                          component={PostComponent}
                        />
                        <Route
                          exact
                          path="/contact/:location?"
                          component={ContactComponent}
                        />
                        <Route
                          path="/contact/:employeeName"
                          component={ContactComponent}
                        />
                        <Route
                          path="/impressum"
                          component={ImpressumComponent}
                        />
                        <Route
                          path="/privacy"
                          component={PrivacyComponent}
                        />
                        <PrivateRoute
                          exact
                          path="/new-employee"
                          component={NewEmployeeComponent}
                        />
                        <PrivateRoute
                          exact
                          path="/new-post"
                          component={NewPostComponent}
                        />
                        <PrivateRoute
                          exact
                          path="/settings"
                          component={SettingsComponent}
                        />
                      </Switch>
                    </AnimatePresence>
                  );
                }}
              />
              <SubFooterComponent
                title="Jetzt kennst du uns – Zeit, dass wir dich kennenlernen!"
                btnText="Jetzt Termin vereinbaren"
              />
              <FooterComponent />
              <ModalComponent />
            </ModalProvider>
          </AuthProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
