import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import { ArrowDropDown } from '@material-ui/icons';
const DropdownWidget = ({options, withCheckbox, checked, index}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        sortDays();
        // eslint-disable-next-line
    }, [checked])

    const sorter = {
        'montag': 1,
        'dienstag': 2,
        'mittwoch': 3,
        'donnerstag': 4,
        'freitag': 5,
        'samstag': 6,
        'sonntag': 7
    }

    const sortDays = () => {
        let tmp = [];
        Object.keys(options).forEach((key) => {
            let value = options[key];
            let index = sorter[key.toLowerCase()];
            tmp[index] = {
                key: key,
                value: value
            };
        });

        let orderedData = {};
        tmp.forEach(function(obj) {
            orderedData[obj.key] = obj.value;
        });

        setData(orderedData);
    }

    return <div className="dropdown">
        <div className={classnames("btn-drop", {'open': isOpen})} onClick={() => setIsOpen(!isOpen)}>
            {!isOpen && data ? <><p>{_.isEmpty(_.map(_.filter(Array.from(Object.keys(data)), op => data[op].index === index), v => v.slice(0, 2))) ? 'Tage auswählen' : _.map(_.filter(Array.from(Object.keys(data)), op => data[op].index === index), v => v.slice(0, 2)).join(', ')}</p><ArrowDropDown/></> : <><p>Tage auswählen</p>
            <ArrowDropDown/></>}
        </div>
        <ul className={classnames('drop-container', {'open': isOpen})}>
            {data && _.map(data, (option, key) => {
                return <li key={key} className={classnames({'disabled': option.value && option.index !== index})} disabled>{withCheckbox && <span data-option={key} className={classnames('check', {'checked': option.value})} onClick={e => {
                    if (index === option.index || _.isNull(option.index)) {
                        checked({index, 'checked': {...data, [key]: {value: !data[key].value, 'index': data[key].value ? null : index}}});
                    }
                }}/>} {key}</li>
            })}
        </ul>
    </div>
}

export default DropdownWidget;