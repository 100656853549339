import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { AddPhotoAlternateOutlined, HourglassEmpty, KeyboardArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import * as _ from 'lodash';
import { addNewEmployee } from '../../utils/database/employee';
import Compressor from 'compressorjs';
import {v4} from 'uuid';
import {Helmet} from 'react-helmet-async';

const NewEmployeeComponent = () => {
    const history = useHistory();
    const [imagePreview, setImagePreview] = useState();
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [description, setDescription] = useState('');
    const [available, setAvailable] = useState(false);
    const [compressedFile, setCompressedFile] = useState(null);
    const [isCompressing, setIsCompressing] = useState('stop');

    const onDrop = (pictureFile, pictureDataURL) => {
        const image = pictureFile && pictureFile[0] && pictureFile[0].type === 'image/svg+xml' ? new Blob(pictureFile, {type: 'image/svg+xml'}) : new Blob(pictureFile,{type: 'image/jpeg'});
        setIsCompressing('start');
        new Compressor(image, {
            mimeType: 'image/webp',
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            checkOrientation: false,
            success: (compressedResult) => {
                compressedResult.name = v4() + '.webp';
                setCompressedFile(compressedResult);
                setImagePreview(URL.createObjectURL(compressedResult))
                setIsCompressing('done');
            },
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && !_.isEmpty(compressedFile)) {
            addNewEmployee({
                name,
                'surname': surname || '',
                'description': description || '',
                image: compressedFile
            });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (name && !_.isEmpty(compressedFile)) {
            setAvailable(true);
        }
    }, [name, surname, compressedFile, description]);

    return <section id="new-employee">
        <Helmet>
            <meta name="description" content="Stellen Sie ein neue Mitarbeiter" />
            <meta property="og:description" content="Stellen Sie ein neue Mitarbeiter" />
            <meta name="keywords" content="Tattoo, Piercing, Stuttgart, Stuttgart-Mitte, Team, Staff, Mitarbeiter, City, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort" />
            <meta property="og:title" content="Neue Mitarbeiter – Otra Vida Tattoo & Piercing Stuttgart" />
            <meta property="og:image" content="/assets/images/otra-vida_fb-image.png"/>
            <title>Neue Mitarbeiter – Otra Vida Tattoo & Piercing Stuttgart</title>
        </Helmet>
        <div className="section-header">
            <KeyboardArrowLeft className="btn-back" onClick={() => history.push('/team')}/>
            <div className="btn-grouping">
                <button className={classnames("btn-primary", {'disabled': !available})} onClick={handleSubmit}>Speichern</button>
                <button className="btn-secondary" onClick={() => history.push('/team')}>Abbrechen</button>
            </div>
        </div>
        <div className="section-body">
            <div className="flex-box">
                <input className="post-title" placeholder="Vorname" type="text" name="name" onChange={e => setName(e.target.value)}/>
                <input className="post-title" placeholder="Nachname" type="text" name="surname" onChange={e => setSurname(e.target.value)}/>
            </div>
            <div className="flex-box">
                <div className="image-box">
                    <ImageUploader maxFileSize={13631488} withIcon={false} buttonText={''} imgExtension={['.jpg', '.png', '.jpeg', '.svg']} singleImage={true} onChange={onDrop} fileTypeError='Ungültiger Dateityp' fileSizeError='Ungültige Dateigröße'/>
                    <div className="uploader-info">
                        {isCompressing === 'start' && <>
                                <HourglassEmpty className="compression-icon"/>
                                <h3>Laden...</h3>
                        </>}
                        {(isCompressing === 'done' || isCompressing === 'stop') && <>
                            <AddPhotoAlternateOutlined/>
                            <h3>Dateien durchsuchen...</h3>
                        </>}
                    </div>
                    {imagePreview && <img className="image-preview" src={imagePreview} alt={compressedFile.name}/>}
                </div>
                <textarea type="text" className="post-description" name="description" placeholder="Beschreibung hinzufügen..." onChange={e => setDescription(e.target.value)}/>
            </div>
        </div>
    </section>
}

export default NewEmployeeComponent;