import { AddPhotoAlternateOutlined, Facebook, FormatListBulletedTwoTone, HourglassEmpty, KeyboardArrowLeft } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import { deletePost, getPost, updatePost } from '../../utils/database/posts';
import * as _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import TagInputWidget from '../../widgets/tag.input.widget';
import Linkify from 'react-linkify';
import {motion} from 'framer-motion';
import {Helmet} from 'react-helmet-async';
import Compressor from 'compressorjs';
import { useModal } from '../../contexts/modal.context';
import {v4} from 'uuid';

const PostComponent = () => {
    const { openModal, answer, data } = useModal();
    const [post, setPost] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const {currentUser} = useAuth();
    const {postTitle} = useParams();
    const history = useHistory();

    const [imagePreview, setImagePreview] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState();

    const [compressedFile, setCompressedFile] = useState(null);
    const [isCompressing, setIsCompressing] = useState('stop');

    useEffect(() => {
        if (!post) {
            window.scrollTo(0, 0);
            getPost(postTitle).then(res => setPost(res));
        }
        if (answer && !_.isEmpty(data)) {
            handleDeletePost();
        }
        // eslint-disable-next-line
    });

    useEffect(() => {
        if (post) {
            setDescription(post.description);
            setTitle(post.title);
        }
        // eslint-disable-next-line
    }, [post])

    const onDrop = (pictureFile, pictureDataURL) => {
        const image = pictureFile && pictureFile[0] && pictureFile[0].type === 'image/svg+xml' ? new Blob(pictureFile, {type: 'image/svg+xml'}) : new Blob(pictureFile,{type: 'image/jpeg'});
        setIsCompressing('start');
        new Compressor(image, {
            mimeType: 'image/webp',
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            checkOrientation: false,
            success: (compressedResult) => {
                compressedResult.name = v4() + '.webp';
                setCompressedFile(compressedResult);
                setImagePreview(URL.createObjectURL(compressedResult));
                setIsCompressing('done');
            },
        });
    }

    const handleDeletePost = () => {
        deletePost(postTitle, post.imageName);
    }

    const handleEdit = (e) => {
        e.preventDefault();
        if (isEdited) {
            if (title !== '' && post.title !== title) {
                if (compressedFile) {
                    updatePost({
                        'title': title,
                        'description': description || post.description,
                        'tags': !_.isEmpty(tags) ? tags : FormatListBulletedTwoTone,
                        'image': compressedFile,
                        'createdAt': post.createdAt
                    }, {
                        deletePost: true,
                        oldPostId: post.title,
                        oldPostImageName: post.imageName,
                        imageChanged: true
                    });
                } else {
                    updatePost({
                        'title': title,
                        'description': description || post.description,
                        'tags': !_.isEmpty(tags) ? tags : false,
                        'imageUrl': post.imageUrl,
                        'imageName': post.imageName,
                        'createdAt': post.createdAt
                    }, {
                        deletePost: true,
                        oldPostId: post.title,
                        imageChanged: false
                    });
                }
            } else {
                if (compressedFile) {
                    updatePost({
                        'title': post.title,
                        'description': description || post.description,
                        'tags': !_.isEmpty(tags) ? tags : false,
                        'image': compressedFile,
                        'createdAt': post.createdAt
                    }, {
                        deletePost: false,
                        oldPostImageName: post.imageName,
                        imageChanged: true
                    });
                } else {
                    updatePost({
                        'title': post.title,
                        'description': description || post.description,
                        'tags': !_.isEmpty(tags) ? tags : false,
                        'imageUrl': post.imageUrl,
                        'imageName': post.imageName,
                        'createdAt': post.createdAt
                    }, {
                        deletePost: false,
                        imageChanged: false
                    });
                }
            }
        } else {
            setIsEdited(true);
        }
    }

    return <section id="post">
        {post ? <>
            <Helmet>
                <meta name="description" content={post.description} />
                <meta name="keywords" content={"Tattoo, Studio, Stuttgart, Otra, Vida, Galerie, Fotos, Instagram, " + (post.tags && !_.isEmpty(post.tags) ? post.tags.join(', ') : '')} />
                <meta property="og:title" content={post.title + '- Otra Vida Tattoo & Piercing Stuttgart'} />
                <meta property="og:description" content={post.description} />
                <meta property="og:image" content={post.imageUrl}/>
                <title>{post.title} - Otra Vida Tattoo & Piercing Stuttgart</title>
            </Helmet>
            <div className="section-header">
                <KeyboardArrowLeft className="btn-back" onClick={() => history.push('/news')}/>
                {currentUser && <div className="btn-grouping">
                    <button className={classnames("btn-primary", {'disabled': isEdited && (title === '' || description === '')})} onClick={handleEdit}>{isEdited ? 'Speichern' : 'Bearbeiten'}</button>
                    {isEdited && <button className={classnames("btn-secondary")} onClick={() => {
                        setIsEdited(false)
                        setImagePreview(null);
                    }}>Abbrechen</button>}
                    <button className="btn-secondary" onClick={() => openModal('Post löschen', <p>Möchten Sie <b>"{title}"</b> wirklich löschen?</p>, {'title': post.title, 'imageName': post.imageName})}>Löschen</button>
                </div>}
            </div>
            <div className={classnames("section-body", {'editable': isEdited})}>
                <span className="post-date">Veröffentlicht am {moment(post.createdAt).format('DD.MM.YYYY HH:mm')}</span>
                {isEdited ? <motion.input initial={{opacity: 0, y: '40px'}} animate={{opacity: 1, y: 0, transition: {duration: 1, ease: 'easeInOut', delay: 0.5}}} exit={{opacity: 0, y: "40px", transition: {duration: 1, ease: 'easeInOut'}}} className="post-title" defaultValue={post.title} type="text" name="title" onChange={e => setTitle(e.target.value)}/> : <motion.h1 initial={{opacity: 0, y: '40px'}} animate={{opacity: 1, y: 0, transition: {duration: 1, ease: 'easeInOut', delay: 0.5}}} exit={{opacity: 0, y: "40px", transition: {duration: 1, ease: 'easeInOut'}}} className="post-title">{post.title}</motion.h1>}
                <motion.a initial={{opacity: 0, y: '-20px'}} animate={{opacity: 1, y: 0, transition: {duration: 1, ease: 'easeInOut', delay: .5}}} exit={{opacity: 0, y: "-20px", transition: {duration: 1, ease: 'easeInOut'}}} href={'https://www.facebook.com/sharer/sharer.php?u='+process.env.REACT_APP_DOMAIN_URL+'news/'+post.title.split(' ').join('-').toLowerCase()} target="_balnk" className="share-box">
                    <Facebook/> Auf Facebook teilen
                </motion.a>
                <div className="flex-box">
                    {isEdited && <motion.div initial={{opacity: 0, x: '-40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: 0, x: "-40px", transition: {duration: 1, ease: 'easeInOut'}}} className="image-box">
                        <ImageUploader maxFileSize={13631488} withIcon={false} buttonText={''} imgExtension={['.jpg', '.png', '.jpeg', '.svg']} singleImage={true} onChange={onDrop} fileTypeError='Ungültiger Dateityp' fileSizeError='Ungültige Dateigröße'/>
                        <div className="uploader-info">
                            {isCompressing === 'start' && <>
                                <HourglassEmpty className="compression-icon"/>
                                <h3>Laden...</h3>
                            </>}
                            {(isCompressing === 'done' || isCompressing === 'stop') && <>
                                <AddPhotoAlternateOutlined/>
                                <h3>Dateien durchsuchen...</h3>
                            </>}
                        </div>
                        <img className="image-preview edited" src={imagePreview ? imagePreview : post.imageUrl} alt={post.title}/>
                    </motion.div>}
                    {!isEdited && <motion.div initial={{opacity: 0, x: '-40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: 0, x: "-40px", transition: {duration: 1, ease: 'easeInOut'}}} className="image-box">
                        <img className="image-preview" src={imagePreview ? imagePreview : post.imageUrl} alt={post.title}/>
                    </motion.div>}
                    {isEdited ? <motion.textarea type="text" className="post-description" name="description" defaultValue={post.description} onChange={e => setDescription(e.target.value)} initial={{opacity: 0, x: '40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: 0, x: "40px", transition: {duration: 1, ease: 'easeInOut'}}}/> : <motion.p initial={{opacity: 0, x: '40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: 0, x: "40px", transition: {duration: 1, ease: 'easeInOut'}}} className="post-description"><Linkify properties={{target: '_blank'}}>{post.description}</Linkify></motion.p>}
                </div>
                {isEdited ? <label>
                    <p>Tags</p>
                    <TagInputWidget hasTags={post.tags} onInputChange={t => setTags(t)}/>
                </label> : !_.isEmpty(post.tags) && <ul className="post-tags">
                    {_.map(post.tags, (tag, index) => {
                        return <motion.li initial={{opacity: 0, y: '20px'}} animate={{opacity: 1, y: 0, transition: {duration: 1, ease: 'easeInOut', delay: index / 2}}} exit={{opacity: 0, y: "20px", transition: {duration: 1, ease: 'easeInOut', delay: '.'+index}}} key={index}>{tag}</motion.li>
                    })}
                </ul>}
            </div>
        </>
        : <h1>Laden...</h1>}
    </section>
}

export default PostComponent;