import React, { useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import * as _ from 'lodash';
import moment from 'moment';
import {motion} from 'framer-motion';
import classnames from 'classnames';
import { getAllPosts } from '../../utils/database/posts';
import { ArrowRightAlt } from '@material-ui/icons';
import {Helmet} from 'react-helmet-async';

const NewsComponent = () => {
    const {currentUser} = useAuth();
    const [posts, setPosts] = useState();
    const [tags, setTags] = useState([]);
    const [filtered, setFiltered] = useState('');
    const history = useHistory();

    const containerVariants = {
        hidden: {
            opacity: 0,
            x: '100vw',
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
                delay: 0,
            }
        },
        exit: {
            y: '-100vh',
            opacity: 0,
            transition: {
                ease: 'easeInOut',
                delay: 0,
                duration: 1,
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllPosts().then(p => {
            if (!_.isEmpty(p)) {
                setPosts(p);
                _.map(p, (pt, i) => {
                    if (!_.isEmpty(pt.tags)) {
                        setTags(old => _.uniq([...old, ...pt.tags]))
                    }
                })
            }
        })
    }, []);

    return <motion.section id="news" variants={containerVariants} initial="hidden" exit="exit" animate="visible">
        <Helmet>
            <meta name="description" content="Alle Neews über professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte" />
            <meta name="keywords" content="Tattoo, Piercing, Stuttgart, News, Neuigkeiten, Nachrichten, Blog, Stuttgart-Mitte, City, Termine, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort" />
            <meta propeprty="og:title" content="News – Otra Vida Tattoo & Piercing Stuttgart" />
            <meta propeprty="og:description" content="Alle Neews über professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte" />
            <meta propeprty="og:image" content="/assets/images/otra-vida_fb-image.png"/>
            <title>News – Otra Vida Tattoo & Piercing Stuttgart</title>
        </Helmet>
        <div className="section-header">
            <h1>News</h1>
            {currentUser && <Link className="btn-primary" to="/new-post">Beitrag hinzufügen</Link>}
        </div>
        {posts && tags && <ul className="tags">
            <li className={classnames({'active': '' === filtered})} onClick={() => setFiltered('')}>Alle</li>
            {_.map(tags, (tag, index) => {
                return <motion.li initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{duration: .5, ease: 'easeOut', delay: (index) * .2}} key={index} className={classnames({'active': tag === filtered})} onClick={() => setFiltered(tag)}>{tag}</motion.li>
            })}
        </ul>}
        <div className="section-body posts-box">
            {posts ? _.map(filtered ? _.filter(_.orderBy(posts, 'createdAt', 'desc'), p => !_.isEmpty(p.tags) && p.tags.includes(filtered)) : _.orderBy(posts, 'createdAt', 'desc'), (post, index) => {
                return <motion.div whileHover={{scale: 1.02, boxShadow: 'rgb(0 0 0) 0px 2px 25px -20px', transition: {delay: 0, duration: .5}}} transition={{ease: 'easeInOut'}} exit={{y: 100, opacity: 0, transition: {delay: 0, duration: .5}}} initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0, transition: {duration: 1.5, delay: (index) * .4}}} key={index} onClick={() => history.push('/news/' + post.title.split(' ').join('-').toLowerCase())}className="post-block">
                    <img src={post.imageUrl} alt={post.title}/>
                    <div className="post-desc">
                        <span className="post-date">Veröffentlicht am {moment(post.createdAt).format('DD.MM.YYYY HH:mm')}</span>
                        <h3>{post.title}</h3>
                        <p>{post.description?.substring(0, 155)}...</p>
                        <Link to={'/news/' + post.title.split(' ').join('-').toLowerCase()} className="link-to-post">Mehr lesen <ArrowRightAlt/></Link>
                        <ul className="post-tags">
                            {_.map(post.tags, (tag, i) => {
                                return <li key={i}>{tag}</li>
                            })}
                        </ul>
                    </div>
                </motion.div>
            }) : <div className="information-about-login">
            <h2>😳 Es gibt leider keine Beiträge</h2>
        </div>}
        </div>
    </motion.section>
}

export default NewsComponent;