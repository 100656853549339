import React from 'react';

const AbilitiesWidget = ({title, description, icon}) => {
    return <div className="ability">
        {icon}
        <div className="ability-info">
            <h4 className="ability-title">{title}</h4>
            <p className="ability-description">{description}</p>
        </div>
    </div>
}

export default AbilitiesWidget;