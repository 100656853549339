import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import { Close } from '@material-ui/icons';
import { useModal } from '../contexts/modal.context';

const containerVariants = {
    hide: {
        opacity: 0,
        y: '100%',
        x: '-50%'
    },
    show: {
        opacity: 1,
        y: '-50%',
        x: '-50%',
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    },
    end: {
        y: '-100vh',
        opacity: 0,
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}
const backdropVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    },
    exit: {
        opacity: 0
    }
}

const ModalComponent = () => {
    const {callback, title, content, isOpen} = useModal();
    return <AnimatePresence exitBeforeEnter>
        {isOpen && <motion.div variants={backdropVariants} initial="hidden" animate="visible" exit="exit" className="backdrop" key="modal-backdrop"/>}
        {isOpen && <motion.div variants={containerVariants} initial="hide" animate="show" exit="end" className="modal" key="modal">
            <div className="modal-header">
                <h3>{title}</h3>
                <Close onClick={() => callback(false)}/>
            </div>
            <div className="modal-body">
                {content}
            </div>
            <div className="modal-footer">
                <div className="btn-grouping">
                    <button className="btn-primary-dark" onClick={() => callback(true)}>Ja</button>
                    <button className="btn-secondary-dark" onClick={() => callback(false)}>Nein</button>
                </div>
            </div>
        </motion.div>}
    </AnimatePresence>
}

export default ModalComponent;