import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import classnames from 'classnames';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

const LoginComponent = () => {
    const {signIn} = useAuth();
    const [errMsg, setErrMsg] = useState('');
    const [logErr, setLogErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const {email, password} = e.target.elements;
        if (email.value && password.value) {
            signIn(email.value, password.value).then(response => {
                if (response.code === "auth/wrong-password") {
                    setErrMsg('Du hast ein falsches Passwort eingegeben.');
                    setPassErr(true);
                } else if (response.code === "auth/user-not-found") {
                    setErrMsg('Benutzer existiert nicht.');
                    setLogErr(true);
                } else if (response.code === 'auth/too-many-requests') {
                    setErrMsg('Das Konto wurde aufgrund zu vieler Anmelderversuche vorübergehend gesperrt. Bitte versuchen Sie es später erneuet.');
                    setLogErr(true);
                } else {
                    setErrMsg('');
                    setLogErr(false);
                    history.push('/');
                }
            });
        } else {
            setPassErr(true);
            setLogErr(true);
            setErrMsg('Bitte fülle alle Felder aus.');
        }
    }

    return <section id="login">
        <Helmet>
            <meta name="description" content="Login seite" />
            <meta name="keywords" content="Tattoo, Piercing, Stuttgart, News, Neuigkeiten, Nachrichten, Blog, Stuttgart-Mitte, City, Termine, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort, Login, Sign In" />
            <meta propeprty="og:title" content="Login - Otra Vida Tattoo & Piercing Stuttgart" />
            <meta propeprty="og:description" content="Login seite" />
            <meta propeprty="og:image" content="/assets/images/otra-vida_fb-image.png"/>
            <title>Login - Otra Vida Tattoo & Piercing Stuttgart</title>
        </Helmet>
        <h1>Einloggen</h1>
        <form onSubmit={handleSubmit}>
            {errMsg && <p className="err-msg">{errMsg}</p>}
            <label className={classnames("require", {'has-error': logErr})}>
                <p>E-Mail</p>
                <input type="email" name="email"/>
            </label>
            <label className={classnames("require", {'has-error': passErr})}>
                <p>Passwort</p>
                <input type="password" name="password"/>
            </label>
            <button className="btn-primary">Einloggen</button>
        </form>
    </section>
}

export default LoginComponent;