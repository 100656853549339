import { firestore, storage } from '../firebase';
import * as _ from 'lodash';
import { getCoordinates } from '../contact';
import { default as XingIcon } from '../../assets/icons/xing_logo.png';

const firestoreSave = async (coordinates, locationData, imageUrl, imageName, imagePath) => {
  if (coordinates) {
    try {
      firestore
        .collection('settings')
        .doc(locationData.id)
        .set({
          ...locationData,
          homepage: {
            title: locationData.homepage.title,
            description: locationData.homepage.description,
            image: imageUrl || locationData.homepage.image || '',
            imageName: imageName || '',
            imagePath: imagePath ||''
          },
          coordinates,
        });
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      firestore
        .collection('settings')
        .doc(locationData.id)
        .set({
          ...locationData,
          homepage: {
            title: locationData.homepage.title,
            description: locationData.homepage.description,
            image: imageUrl || locationData.homepage.image,
            imageName: imageName || '',
            imagePath: imagePath ||''
          },
          coordinates: false,
        });
    } catch (err) {
      console.log(err);
    }
  }
};

export const saveSettings = async (data, oldSettings, callback) => {
  if (oldSettings && !_.isEqual(data, oldSettings)) {
    Object.keys(oldSettings).forEach((oldKey) => {
      if (!_.includes(Object.keys(data), oldKey)) {
        firestore.collection('settings').doc(oldKey).delete();
      }
    });
  }

  return _.map(data, async (locationData, key) => {
    const fullAddress =
      locationData.address.street &&
      locationData.address['street-number'] &&
      locationData.address['post-code'] &&
      locationData.address.city;

    let coordinates =
      fullAddress &&
      await getCoordinates(locationData.address).then(geo => geo);
    if (locationData.homepage && typeof locationData.homepage.image === 'object') {
      if (oldSettings[locationData.id] && oldSettings[locationData.id].homepage.imagePath) {
        storage.ref(oldSettings[locationData.id].homepage.imagePath).delete();
      }
      await storage
        .ref('locations-images/' + locationData.homepage.image.name)
        .put(locationData.homepage.image)
        .then((val) => {
          val.ref.getDownloadURL().then((imageUrl) => {
            firestoreSave(coordinates, locationData, imageUrl, val.ref.name, val.ref.fullPath).then(() => callback());
          });
        });
    } else {
      firestoreSave(coordinates, locationData).then(() => callback());
    }
  });
};

export const deleteLocation = async (locationId, imagePath) => {
  try {
    if (imagePath) {
      return await storage.ref(imagePath).delete().then(res => {
        firestore.collection('settings').doc(locationId).delete();
      });
    } else {
      return await firestore.collection('settings').doc(locationId).delete();
    }
  } catch (err) {
    console.log(err.message);
  }
}

export const getSocialMedia = async () => {
  try {
    return await firestore
      .collection('settings')
      .get()
      .then((snap) => {
        const locations = [];
        snap.forEach((doc) => {
          if (
            doc.exists &&
            _.filter(doc.data()['socialMedia'], 'active').length > 0
          ) {
            locations.push({
              locationName: doc.data().locationName,
              socialMedia: _.filter(
                doc.data()['socialMedia'],
                'active'
              ),
            });
          }
        });
        return locations;
      });
  } catch (err) {
    console.log(err);
  }
};

export const getAllHomepageLocations = async () => {
  try {
    return await firestore
      .collection('settings')
      .get()
      .then((snap) => {
        const locations = [];
        snap.forEach((doc) => {
          locations.push({
            locationName: doc.data().locationName,
            address: doc.data().address,
            company: doc.data().company,
            owner: doc.data().owner,
            contact: {
              phone: doc.data()['phone'],
              email: doc.data().email,
            },
            openHours: doc.data()['open-hours'],
            coordinates: doc.data().coordinates,
            gallery: doc.data().locationInstApi,
            createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : null,
            socialMedia: _.filter(
                doc.data()['socialMedia'],
                'active'
            ),
            ...doc.data().homepage,
          });
        });
        return _.orderBy(locations, 'createdAt', 'asc');
      });
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

export const initialSocialMedia = {
  google: {
    url: '', // https://www.google.com/search?q=otra+vida+stuttgart&rlz=1C1CHBF_plDE835DE835&oq=otra+vida+stuttgart&aqs=chrome..69i57j69i60l3.5755j0j7&sourceid=chrome&ie=UTF-8#lrd=0x4799db49537e1ec9:0x7934096e71d85993,3,,,
    active: false,
    name: 'Google',
  },
  facebook: {
    url: '',
    active: false,
    name: 'Facebook',
  },
  youtube: {
    url: '',
    active: false,
    name: 'Youtube',
  },
  pinterest: {
    url: '',
    active: false,
    name: 'Pinterest',
  },
  instagram: {
    url: '',
    active: false,
    name: 'Instagram',
  },
  twitter: {
    url: '',
    active: false,
    name: 'Twitter',
  },
  linkedIn: {
    url: '',
    active: false,
    name: 'LinkedIn',
  },
  xing: {
    url: '',
    active: false,
    name: 'Xing',
    image: XingIcon,
  },
};

export const initialOwner = {
  name: '',
  surname: '',
};

export const initialAdress = {
  city: '',
  'post-code': '',
  street: '',
  'street-number': '',
};

export const initialEmail = '';
export const initialPhoneNumber = '';
export const initialCompanyName = '';
