import React, { useEffect, useState} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useHistory} from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import { deleteEmployee, updateEmployee } from '../utils/database/employee';
import { useModal } from '../contexts/modal.context';
import _ from 'lodash';
import classnames from 'classnames';
import Linkify from 'react-linkify';
import ImageUploader from 'react-images-upload';
import { AddPhotoAlternateOutlined, HourglassEmpty } from '@material-ui/icons';
import Compressor from 'compressorjs';
import {v4} from 'uuid';
import { useInView } from 'react-intersection-observer';

const EmployeeWidget = ({name, surname, description, imgPath, n, imgName, createdAt}) => {
    const { openModal, answer, data } = useModal();
    const { currentUser } = useAuth();
    const [isEdited, setIsEdited] = useState(false);
    const history = useHistory();
    const [compressedFile, setCompressedFile] = useState(null);
    const [isCompressing, setIsCompressing] = useState('stop');

    const [imagePreview, setImagePreview] = useState();
    const [editedName, setEditedName] = useState('');
    const [editedSurname, setEditedSurname] = useState('');
    const [editedDescription, setEditedDescription] = useState('');

    const controls = useAnimation();
    const {ref, inView} = useInView({
        threshold: 0,
    });

    const imageVariants = {
        hidden: {
            x: n % 2 === 0 ? '40vw' : '-40vw',
            opacity: 0,
            transition: {
                duration: 2,
                ease: 'backIn'
            }
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 2,
            ease: 'backOut'
          }
        }
    }
    const titleVariants = {
        hidden: {
            y: n % 2 === 0 ? '40px' : '-40px',
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'backInOut'
            }
        },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
            ease: 'backInOut'
          }
        }
    }
    const descVariants = {
        hidden: {
            y: n % 2 === 0 ? '20px' : '-20px',
            opacity: 0,
            transition: {
                duration: 1,
                delay: .5,
                ease: 'easeInOut'
            }
        },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
            delay: .5,
            ease: 'easeInOut'
          }
        }
    }
    const linkVariants = {
        hidden: {
            x: n % 2 === 0 ? '-30px' : '30px',
            opacity: 0,
            transition: {
                duration: 1,
                delay: .5,
                ease: 'easeInOut'
            }
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            delay: .5,
            ease: 'easeInOut'
          }
        }
    }

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
    }, [controls, inView]);

    useEffect(() => {
        if (answer && !_.isEmpty(data)) {
            const employeeFullName = data.name + ' ' + data.surname;
            deleteEmployee(employeeFullName.split(' ').join('-').toLowerCase(), data.imageName);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onDrop = (pictureFile, pictureDataURL) => {
                const image = pictureFile && pictureFile[0] && pictureFile[0].type === 'image/svg+xml' ? new Blob(pictureFile, {type: 'image/svg+xml'}) : new Blob(pictureFile,{type: 'image/jpeg'});
        setIsCompressing('start');
        new Compressor(image, {
            mimeType: 'image/webp',
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            checkOrientation: false,
            success: (compressedResult) => {
                compressedResult.name = v4() + '.webp';
                setCompressedFile(compressedResult);
                setImagePreview(URL.createObjectURL(compressedResult))
                setIsCompressing('done');
            },
        });
    }

    const handleEdit = (e) => {
        e.preventDefault();
        if (isEdited) {
            if ((name !== editedName && editedName !== '') || (editedSurname !== surname && editedSurname !== '')) {
                if (compressedFile) {
                    updateEmployee({
                        'name': editedName || name,
                        'surname': editedSurname || surname,
                        'description': editedDescription || description,
                        'image': compressedFile,
                        'createdAt': createdAt
                    }, {
                        deleteEmp: true,
                        oldEmpId: name + ' ' + surname,
                        oldEmpImageName: imgName,
                        imageChanged: true
                    });
                } else {
                    updateEmployee({
                        'name': editedName || name,
                        'surname': editedSurname || surname,
                        'description': editedDescription || description,
                        'imageUrl': imgPath,
                        'imageName': imgName,
                        'createdAt': createdAt
                    }, {
                        deleteEmp: true,
                        oldEmpId: name + ' ' + surname,
                        imageChanged: false
                    });
                }
            } else {
                if (compressedFile) {
                    updateEmployee({
                        'name': name,
                        'surname': surname,
                        'description': editedDescription || description,
                        'image': compressedFile,
                        'createdAt': createdAt
                    }, {
                        deleteEmp: false,
                        oldEmpImageName: imgName,
                        imageChanged: true
                    });
                } else {
                    updateEmployee({
                        'name': name,
                        'surname': surname,
                        'description': editedDescription || description,
                        'imageUrl': imgPath,
                        'imageName': imgName,
                        'createdAt': createdAt
                    }, {
                        deleteEmp: false,
                        imageChanged: false
                    });
                }
            }
        } else {
            setIsEdited(true);
        }
    }

    return <div className={classnames("employee-widget", {'editable': isEdited})} key={n} ref={ref}>
        {isEdited && <motion.div className="flex-box" initial={{opacity: window.innerWidth > 768 ? 0 : 1, y: '-40px'}} animate={{opacity: 1, y: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: window.innerWidth > 768 ? 0 : 1, transition: {duration: 1, ease: 'easeInOut'}}}>
                <input className="post-title" defaultValue={name} type="text" name="name" onChange={e => setEditedName(e.target.value)}/>
                <input className="post-title" defaultValue={surname} type="text" name="surname" onChange={e => setEditedSurname(e.target.value)}/>
            </motion.div>}
        {!isEdited && <motion.h2 ref={ref} animate={controls} initial="hidden" variants={titleVariants} className="employee-name">{window.innerWidth > 768 ? name + ' ' + surname : <span>{name} <br/>{surname}</span>}</motion.h2>}
        {isEdited && <div className="flex-box reverse-box">
            <motion.div initial={{opacity: window.innerWidth > 768 ? 0 : 1, x: '40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: window.innerWidth > 768 ? 0 : 1, transition: {duration: 1, ease: 'easeInOut'}}} className="image-box">
                <ImageUploader maxFileSize={13631488} withIcon={false} buttonText={''} imgExtension={['.jpg', '.png', '.jpeg', '.svg']} singleImage={true} onChange={onDrop} fileTypeError='Ungültiger Dateityp' fileSizeError='Ungültige Dateigröße'/>
                <div className="uploader-info">
                    {isCompressing === 'start' && <>
                        <HourglassEmpty className="compression-icon"/>
                        <h3>Laden...</h3>
                    </>}
                    {(isCompressing === 'done' || isCompressing === 'stop') && <>
                        <AddPhotoAlternateOutlined/>
                        <h3>Dateien durchsuchen...</h3>
                    </>}
                </div>
                {imagePreview ? <img className="image-preview" src={imagePreview} alt={compressedFile.name} /> : <img className="image-preview" src={imgPath} alt={name + ' ' + surname}/>}
            </motion.div>
            <motion.textarea initial={{opacity: window.innerWidth > 768 ? 0 : 1, x: '-40px'}} animate={{opacity: 1, x: 0, transition: {duration: 1, ease: 'easeInOut'}}} exit={{opacity: window.innerWidth > 768 ? 0 : 1, transition: {duration: 1, ease: 'easeInOut'}}} type="text" className="post-description" name="description" defaultValue={description} onChange={e => setEditedDescription(e.target.value)}/>
        </div>}
        {!isEdited && <motion.img animate={controls} initial="hidden" variants={imageVariants} className="employee-image" src={imgPath}/>}
        {!isEdited && description && <motion.p animate={controls} initial="hidden" variants={descVariants} className="employee-description">
            <Linkify>{description}</Linkify>
        </motion.p>}
        {!currentUser && <motion.button className="btn-secondary" onClick={() => {
            history.push('/contact/'+name.split(' ').join('-').toLowerCase() + '-' + surname.split(' ').join('-').toLowerCase())
        }}>Termin vereinbaren</motion.button>}
        {currentUser && <motion.div className="btn-grouping" animate={controls} initial="hidden" variants={linkVariants}>
            {isEdited && <motion.button className="btn-primary" onClick={handleEdit}>Speichern</motion.button>}
            {isEdited && <motion.button className="btn-secondary" onClick={() => setIsEdited(false)}>Abbrechen</motion.button>}
            {!isEdited && <motion.button className="btn-secondary" onClick={() => setIsEdited(true)}>Bearbeiten</motion.button>}
            {!isEdited && <motion.button className="btn-secondary" onClick={() => openModal('Mitarbeiter löschen', <p>Möchten Sie <b>{name} {surname}</b> wirklich löschen?</p>, {name, surname, imageName: imgName})}>Löschen</motion.button>}
        </motion.div>}
    </div>
}
export default EmployeeWidget;