import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Instagram, LocationOn } from '@material-ui/icons';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/auth.context';
import axios from 'axios';

const GalleryComponent = () => {
  const [photos, setPhotos] = useState();
  const { locations } = useAuth();
  const [once, setOnce] = useState(false);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: '-100vh'
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        delay: 0
      }
    },
    exit: {
      x: '-100vw',
      opacity: 0,
      transition: {
        ease: 'easeInOut',
        delay: 0,
        duration: 1
      }
    }
  };

  const instagramApi = {
    getPublicImages: async (
      locationId,
      accessToken,
      locationName,
      socialMedia
    ) => {
      const apiUrl = `https://graph.instagram.com/me/media?fields=id,media_url,media_type&access_token=${accessToken}`;
      await axios.get(apiUrl).then((res) => {
        const data = res.data.data;
        const mediaArray = [];
        data.map((media) => {
          mediaArray.push({ url: media.media_url, type: media.media_type });
        });
        setPhotos((old) => ({
          ...old,
          [locationId]: { mediaArray, locationName, socialMedia }
        }));
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (locations && !once) {
      setOnce(true);
      locations.map((location) => {
        console.log(location);
        if (location.gallery) {
          instagramApi.getPublicImages(
            location.locationName.split(' ').join('-').toLowerCase(),
            location.gallery,
            location.locationName,
            location.socialMedia
          );
        }
      });
    }
  }, [locations]);

  return (
    <motion.section
      variants={containerVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
      id='gallery'
    >
      <Helmet>
        <meta
          name='description'
          content='Unsere Arbeiten, Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte'
        />
        <meta
          name='keywords'
          content='Tattoo, Piercing, Stuttgart, Galerie, Instagram, Feed, Ausstellung, Sammlung, Collection, Stuttgart-Mitte, City, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort'
        />
        <meta
          property='og:title'
          content='Galerie – Otra Vida Tattoo & Piercing Stuttgart'
        />
        <meta
          property='og:description'
          content='Unsere Arbeiten, Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte'
        />
        <meta
          property='og:image'
          content='/assets/images/otra-vida_fb-image.png'
        />
        <title>Galerie – Otra Vida Tattoo & Piercing Stuttgart</title>
      </Helmet>
      <h1>Galerie</h1>
      <div className='flex-box gallery-container' style={{ width: '100%' }}>
        <div
          className='flex-box flex-column'
          style={{ width: '100%', padding: '0 16px 0 0' }}
        >
          <h3 style={{ marginBottom: 0 }}>360° Galerie Stuttgart</h3>
          <iframe
            src='https://cdn5.3dswissmedia.com/360/otravidastuttgart/'
            height='500'
            style={{ marginTop: '3rem', width: '100%' }}
          />
        </div>
        <div
          className='flex-box flex-column'
          style={{ width: '100%', padding: '0 0 0 16px' }}
        >
          <h3 style={{ marginBottom: 0 }}>360° Galerie Fellbach</h3>
          <iframe
            src='https://cdn5.3dswissmedia.com/360/otravidatattoo/'
            height='500'
            style={{ marginTop: '3rem', width: '100%' }}
          />
        </div>
      </div>
      <div className='feeds-box'>
        {_.map(photos, (media, index) => {
          return (
            <div className='gallery-location' key={index}>
              <h4>
                <LocationOn />
                {media.locationName}
              </h4>
              <div className='images-box'>
                {media.mediaArray &&
                  _.map(media.mediaArray, (data, key) => {
                    return (
                      data.type !== 'CAROUSEL_ALBUM' && (
                        <motion.div
                          initial={{ y: 200, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{
                            duration: 1,
                            ease: 'easeInOut',
                            delay: key * 0.2
                          }}
                          className='feed'
                          key={key}
                          disabled
                        >
                          {data.type === 'IMAGE' && (
                            <img
                              src={data.url}
                              alt={'Instagram'}
                              onClick={() => window.open(data.url)}
                            />
                          )}
                          {data.type === 'VIDEO' && (
                            <video
                              src={data.url}
                              alt={'Instagram'}
                              onClick={() => window.open(data.url)}
                              onMouseEnter={(e) => e.target.play()}
                              onMouseOut={(e) => e.target.pause()}
                            />
                          )}
                          <Instagram className='insta-logo' />
                        </motion.div>
                      )
                    );
                  })}
              </div>
              <button
                className='btn-primary'
                onClick={() =>
                  window.open(
                    `${
                      media.socialMedia.find(
                        (item) => item.name === 'Instagram'
                      ).url
                    }`
                  )
                }
              >
                Mehr Bilder
              </button>
            </div>
          );
        })}
      </div>
    </motion.section>
  );
};

export default GalleryComponent;
