import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import * as _ from 'lodash';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import {
  saveSettings,
  initialAdress,
  initialCompanyName,
  initialEmail,
  initialOwner,
  initialPhoneNumber,
  initialSocialMedia,
  deleteLocation,
} from '../../utils/database/settings';
import DayPickerWidget from '../../widgets/daypicker.widget';
import {
  Add,
  AddPhotoAlternateOutlined,
  ArrowForwardIos,
  Close,
  HourglassEmpty,
  LocationOn,
} from '@material-ui/icons';
import { v4 } from 'uuid';
import Compressor from 'compressorjs';
import ImageUploader from 'react-images-upload';

const SettingsComponent = () => {
  const { currentUser, settings, updateState } = useAuth();
  const [isEdited, setIsEdited] = useState(false);
  const [locationNameState, setLocationNameState] = useState(null);
  const [locationsState, setLocationsState] = useState({});
  const [activeLocation, setActiveLocation] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [compressedFile, setCompressedFile] = useState(null);
  const [isCompressing, setIsCompressing] = useState('stop');
  const [sectionBodyWidth, setSectionBodyWidth] = useState(0);
  const [isLocationNameEdited, setIsLocationNameEdited] =
    useState(false);
  const [updateLocation, setUpdateLocation] = useState([]);
  const [addingLocation, setAddingLocation] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (settings) {
      setLocationsState(settings);
      if (!activeLocation) {
        setActiveLocation(settings[Object.keys(settings)[0]].id);
      }
    }
    // eslint-disable-next-line
  }, [currentUser, settings]);

  useEffect(() => {
    if (locationsState && !sectionBodyWidth && document.querySelectorAll('.section-body')[0]) {
      setSectionBodyWidth(
        document.querySelectorAll('.section-body')[0]
          .clientWidth
      )
    }
    if (addingLocation) {
      setAddingLocation(false);
      handleSave();
    }
    // eslint-disable-next-line
  }, [locationsState, sectionBodyWidth]);

  const onDrop = (pictureFile) => {
    const image = new Blob(pictureFile, { type: 'image/jpeg' });
    setIsCompressing('start');
    new Compressor(image, {
      mimeType: 'image/webp',
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      checkOrientation: false,
      success: (compressedResult) => {
        compressedResult.name = v4() + '.webp';
        setCompressedFile(compressedResult);
        setImagePreview(URL.createObjectURL(compressedResult));
        handleUpdateLocation('homepage', {
          title: locationsState[activeLocation].homepage.title,
          description:
            locationsState[activeLocation].homepage.description,
          image: compressedResult,
        });
        setIsCompressing('done');
      },
    });
  };

  const handleAddLocation = (e) => {
    if (showModal && locationNameState) {
      setAddingLocation(true);
      setLocationsState((old) => ({
        ...old,
        [locationNameState.toLowerCase().replaceAll(' ', '-')]: {
          id: locationNameState.toLowerCase().replaceAll(' ', '-'),
          locationName: locationNameState,
          socialMedia: initialSocialMedia,
          address: initialAdress,
          owner: initialOwner,
          company: initialCompanyName,
          email: initialEmail,
          phone: initialPhoneNumber,
          openHours: '',
          locationInstApi: '',
          createdAt: new Date(),
          homepage: {
            title: locationNameState,
            description: '',
            image: '',
          },
        },
      }));
      setActiveLocation(
        locationNameState.toLowerCase().replaceAll(' ', '-')
      );
      setLocationNameState('');
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const handleDeleteLocation = (e) => {
    if (showDeleteModal && activeLocation) {
      const locationImagePath = locationsState[activeLocation].homepage.imagePath;
      deleteLocation(activeLocation, locationImagePath).then(() => {
        updateState().then(() => {
          setIsEdited(false);
          setIsLocationNameEdited(false);
          setUpdateLocation({});
          setShowDeleteModal(false);
        });
        setActiveLocation(settings[Object.keys(settings)[0]].id);
      });
    } else {
      setShowDeleteModal(!showDeleteModal);
    }
  }

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (locationsState && isLocationNameEdited) {
      let newLocationsState;
      let newActiveLocationIndex;
      if (updateLocation) {
        updateLocation.map((location) => {
          if (location.from !== location.to) {
            if (location.from === activeLocation) {
              newActiveLocationIndex = location.to.toLowerCase().replaceAll(' ', '-');
            }
            newLocationsState = {
              ...newLocationsState,
              [location.to]: {
                ...locationsState[location.from],
                id: location.to.toLowerCase().replaceAll(' ', '-'),
                locationName: location.toName,
              },
            };
          }
          return location
        });
        _.map(locationsState, (location, key) => {
          updateLocation.map((l) => {
            if (l.from !== key) {
              newLocationsState = {
                ...newLocationsState,
                [key]: location,
              };
            }
            return l;
          });
          return location;
        });
        saveSettings(newLocationsState, settings, () => {
          updateState().then(() => {
            setIsEdited(false);
            setIsLocationNameEdited(false);
            setUpdateLocation({});
            setActiveLocation(newActiveLocationIndex);
          });
        });
      }
    } else {
      if (locationsState) {
        saveSettings(locationsState, settings, () => {
          updateState().then(() => (setIsEdited(false)));
        });
      }
    }
  };

  const handleCancel = () => setLocationsState(settings);

  const handleUpdateLocation = (key, value) => {
    setLocationsState((old) => ({
      ...old,
      [activeLocation]: {
        ...old[activeLocation],
        [key]: value,
      },
    }));
  };

  const ordered = (objVal) =>
    Object.keys(objVal)
      .sort()
      .reduce((obj, key) => {
        obj[key] = objVal[key];
        return obj;
      }, {});

  const sorter = {
    montag: 1,
    dienstag: 2,
    mittwoch: 3,
    donnerstag: 4,
    freitag: 5,
    samstag: 6,
    sonntag: 7,
  };

  const sortDays = (arr) => {
    return arr.sort((a, b) => {
      return sorter[a.toLowerCase()] - sorter[b.toLowerCase()];
    });
  };
  const getIndex = (idx) => {
    let index = null;
    Object.keys(locationsState).forEach((val, i) => {
      if (val === idx) index = i;
    });
    return index;
  };
  const containerVariants = {
    hide: {
      opacity: 0,
      y: '100%',
      x: '-50%',
    },
    show: {
      opacity: 1,
      y: '-50%',
      x: '-50%',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
    end: {
      y: '-100vh',
      opacity: 0,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };
  const backdropVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return currentUser ? (
    <section id="settings">
      <Helmet>
        <meta
          name="description"
          content="Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte"
        />
        <meta
          name="keywords"
          content="Tattoo, Piercing, Stuttgart, Stuttgart-Mitte, City, Termine, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort"
        />
        <meta
          property="og:title"
          content="Einstellungen – Otra Vida Tattoo & Piercing Stuttgart"
        />
        <meta
          property="og:description"
          content="Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte"
        />
        <meta
          property="og:image"
          content="/assets/images/otra-vida-tattoo_hero-image.png"
        />
        <title>
          Einstellungen – Otra Vida Tattoo & Piercing Stuttgart
        </title>
      </Helmet>
      {showModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="backdrop"
          key="modal-backdrop"
        />
      )}
      {showModal && (
        <motion.div
          variants={containerVariants}
          initial="hide"
          animate="show"
          exit="end"
          className="modal"
          key="modal"
        >
          <div className="modal-header">
            <h3>Standort hinzufügen</h3>
            <Close onClick={() => setShowModal(false)} />
          </div>
          <div className="modal-body">
            <input
              type="text"
              placeholder={'Standort eintragen'}
              onChange={(e) => setLocationNameState(e.target.value)}
            />
          </div>
          <div className="modal-footer">
            <div className="btn-grouping">
              <button
                className="btn-primary-dark"
                onClick={handleAddLocation}
                disabled={!locationNameState}
              >
                Hinzufügen
              </button>
              <button
                className="btn-secondary-dark"
                onClick={() => setShowModal(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </motion.div>
      )}
      {showDeleteModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="backdrop"
          key="modal-backdrop"
        />
      )}
      {showDeleteModal && (
        <motion.div
          variants={containerVariants}
          initial="hide"
          animate="show"
          exit="end"
          className="modal"
          key="modal"
        >
          <div className="modal-header">
            <h3>
              Möchtest du den Standort<br/>
              "{locationsState[activeLocation] ? locationsState[activeLocation].locationName : ''}" wirklich löschen?
            </h3>
            <Close onClick={() => setShowDeleteModal(false)} />
          </div>
          <div className="modal-body">
            <p>Alle Daten des Standorts werden unwiderruflich gelöscht.</p>
          </div>
          <div className="modal-footer">
            <div className="btn-grouping">
              <button
                className="btn-secondary-dark"
                onClick={() => setShowDeleteModal(false)}
              >
                Abbrechen
              </button>
              <button
                className="btn-warning"
                onClick={handleDeleteLocation}
                disabled={!activeLocation}
              >
                Löschen
              </button>
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        className="section-header"
        initial={{ y: '-40px', opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: 'easeInOut' },
        }}
        exit={{ y: '-100vh', opacity: 0 }}
      >
        <h1>Einstellungen</h1>
        <div className="btn-grouping">
          <button
            className="btn-secondary with-icon"
            onClick={handleAddLocation}
          >
            Standort hinzufügen <Add />
          </button>
          <button
            className="btn-secondary"
            onClick={handleDeleteLocation}
          >
            Löschen
          </button>
          {Object.keys(locationsState).length > 0 && (
            <button
              className="btn-secondary"
              onClick={() => {
                setIsEdited(!isEdited);
                if (isEdited) {
                  // setUpdateLocation(locationsState[activeLocation]);
                  handleCancel();
                }
              }}
            >
              {isEdited ? 'Abbrechen' : 'Bearbeiten'}
            </button>
          )}
          {isEdited && Object.keys(locationsState).length > 0 && (
            <button className="btn-primary" onClick={handleSave}>
              Speichern
            </button>
          )}
        </div>
      </motion.div>
      {Object.keys(locationsState).length > 0 && (
        <motion.div>
          <div className="section-sub-header">
            {_.map(locationsState, (location, index) => (
              <div
                className={classnames('location-field', {
                  active: activeLocation === index,
                  disabled: isEdited && activeLocation !== index,
                })}
                onClick={(e) => {
                  if (!isEdited && activeLocation !== index)
                    setActiveLocation(index);
                }}
                key={index}
              >
                <LocationOn />
                {isEdited ? (
                  <input
                    type="text"
                    size="5"
                    disabled={activeLocation !== index}
                    defaultValue={
                      location && location.locationName
                        ? location.locationName
                        : ''
                    }
                    placeholder={
                      !location.locationName
                        ? 'Standort eintragen'
                        : ''
                    }
                    onBlur={(e) => {
                      if (
                        activeLocation !==
                        e.target.value
                          .toLowerCase()
                          .replaceAll(' ', '-')
                      ) {
                        if (!isLocationNameEdited) {
                          setIsLocationNameEdited(true);
                          setUpdateLocation([
                            {
                              from: activeLocation,
                              to: e.target.value
                                .toLowerCase()
                                .replaceAll(' ', '-'),
                              toName: e.target.value,
                            }
                          ])
                          // setUpdateLocation((old) =>
                          //   {
                          //     _.uniqBy(
                          //       [
                          //         ...old,
                                  // {
                                  //   from: activeLocation,
                                  //   to: e.target.value
                                  //     .toLowerCase()
                                  //     .replaceAll(' ', '-'),
                                  //   toName: e.target.value,
                                  // },
                          //       ],
                          //       'id'
                          //     )
                          //   }
                          // );
                        }
                      }
                    }}
                  />
                ) : (
                  <span>{location && location.locationName ? location.locationName : ''}</span>
                )}
                <ArrowForwardIos
                  className={classnames('collapsed-icon', {
                    active: activeLocation === index,
                  })}
                />
              </div>
            ))}
          </div>
        </motion.div>
      )}
      <div className="section-container">
        {Object.keys(locationsState).length > 0 ? (
          _.map(locationsState, (location, index) => (
            <div
              className={classnames('section-body', {
                'not-active': activeLocation !== index,
              })}
              style={{
                marginLeft:
                  getIndex(index) === 0
                    ? getIndex(activeLocation) *
                      (sectionBodyWidth + 26) *
                      -1
                    : 26,
                opacity: activeLocation !== index ? 0.3 : 1,
                filter: `blur(${
                  activeLocation !== index ? '2px' : 0
                })`,
                pointerEvents:
                  activeLocation !== index ? 'none' : 'all',
              }}
              key={index + '-' + location.locationName}
            >
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.2,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.2,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="info-box"
              >
                <h2>Allgemeine Informationen</h2>
                <div className="flex-box">
                  <div
                    className="flex-box flex-column"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <div className="owner">
                      <p>Inhaber</p>
                      <div className="flex-box">
                        {isEdited ? (
                          <>
                            <input
                              type="text"
                              defaultValue={
                                location.owner?.name
                                  ? location.owner.name
                                  : ''
                              }
                              placeholder={
                                !location.owner?.name
                                  ? 'Vorname eintragen'
                                  : ''
                              }
                              onChange={(e) =>
                                handleUpdateLocation('owner', {
                                  name: e.target.value,
                                  surname:
                                    locationsState[activeLocation]
                                      .owner.surname,
                                })
                              }
                            />
                            <input
                              type="text"
                              defaultValue={
                                location.owner?.surname
                                  ? location.owner.surname
                                  : ''
                              }
                              placeholder={
                                !location.owner?.surname
                                  ? 'Nachname eintragen'
                                  : ''
                              }
                              onChange={(e) =>
                                handleUpdateLocation('owner', {
                                  name: locationsState[activeLocation]
                                    .owner.name,
                                  surname: e.target.value,
                                })
                              }
                            />
                          </>
                        ) : (
                          <>
                            <span>
                              {location.owner?.name
                                ? location.owner.name
                                : 'Vorname eintragen'}
                            </span>
                            <span>
                              {location.owner?.surname
                                ? location.owner.surname
                                : 'Nachname eintragen'}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="phone">
                      <p>Firma</p>
                      {!isEdited && (
                        <span>
                          {location.company
                            ? location.company
                            : 'Name der Firma eintragen'}
                        </span>
                      )}
                      {isEdited && (
                        <input
                          type="text"
                          defaultValue={
                            location.company ? location.company : ''
                          }
                          placeholder={
                            !location.company
                              ? 'Name der Firma eintragen'
                              : ''
                          }
                          disabled={!isEdited}
                          className={classnames({
                            disabled: !isEdited,
                          })}
                          onChange={(e) =>
                            handleUpdateLocation(
                              'company',
                              e.target.value
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.4,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.3,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="email-box"
              >
                <h2>Kontakt</h2>
                <div className="flex-box flex-column">
                  <div className="email">
                    <p>E-Mail</p>
                    {!isEdited && (
                      <span>
                        {location.email
                          ? location.email
                          : 'E-Mail eintragen'}
                      </span>
                    )}
                    {isEdited && (
                      <input
                        type="email"
                        defaultValue={
                          location.email ? location.email : ''
                        }
                        placeholder={
                          !location.email ? 'E-Mail eintragen' : ''
                        }
                        disabled={!isEdited}
                        className={classnames({
                          disabled: !isEdited,
                        })}
                        onChange={(e) =>
                          handleUpdateLocation(
                            'email',
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="phone">
                    <p>Telefon/Mobil</p>
                    {!isEdited && (
                      <span>
                        {location.phone
                          ? location.phone
                          : 'Telefon- /Mobilnummer eintragen'}
                      </span>
                    )}
                    {isEdited && (
                      <input
                        type="text"
                        defaultValue={
                          location.phone ? location.phone : ''
                        }
                        placeholder={
                          !location.phone
                            ? 'Telefon- /Mobilnummer eintragen'
                            : ''
                        }
                        disabled={!isEdited}
                        className={classnames({
                          disabled: !isEdited,
                        })}
                        onChange={(e) =>
                          handleUpdateLocation(
                            'phone',
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.2,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.2,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="homepage-box"
                style={{ width: '100%' }}
              >
                <h2 style={{ marginBottom: 0 }}>
                  Informationen zum Standort
                </h2>
                <p style={{ marginBottom: '2rem', opacity: 0.6 }}>
                  Wenn die Daten nicht eingegeben werden,
                  wird der Standort nicht auf der Startseite angezeigt.
                </p>
                <div className="flex-box">
                  <div
                    className="flex-box flex-column"
                    style={{
                      justifyContent: 'flex-start',
                      marginRight: 16,
                    }}
                  >
                    <p>Titel und Beschreibung</p>
                    {isEdited ? (
                      <>
                        <input
                          type="text"
                          defaultValue={
                            location.locationName
                              ? location.locationName
                              : ''
                          }
                          placeholder={
                            !location.homepage?.title
                              ? location.locationName
                              : ''
                          }
                          onChange={(e) =>
                            handleUpdateLocation('homepage', {
                              title: e.target.value,
                              description:
                                locationsState[activeLocation]
                                  .homepage.description,
                              image:
                                locationsState[activeLocation]
                                  .homepage.image,
                            })
                          }
                        />
                        <textarea
                          type="text"
                          defaultValue={
                            location.homepage?.description
                              ? location.homepage.description
                              : ''
                          }
                          placeholder={
                            !location.homepage?.description
                              ? 'Beschreibung eintragen'
                              : ''
                          }
                          onChange={(e) =>
                            handleUpdateLocation('homepage', {
                              title:
                                locationsState[activeLocation]
                                  .homepage.title,
                              description: e.target.value,
                              image:
                                locationsState[activeLocation]
                                  .homepage.image,
                            })
                          }
                        />
                      </>
                    ) : (
                      <>
                        <span>
                          {location.homepage?.title
                            ? location.homepage.title
                            : location.locationName}
                        </span>
                        <span>
                          {location.homepage?.description
                            ? location.homepage.description
                            : 'Beschreibung eintragen'}
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="image-box"
                    style={{ marginLeft: 'auto' }}
                  >
                    {isEdited && (
                      <ImageUploader
                        maxFileSize={10000000}
                        withIcon={false}
                        buttonText={''}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        singleImage={true}
                        onChange={onDrop}
                      />
                    )}
                    {isEdited && (
                      <div className="uploader-info">
                        {isCompressing === 'start' && (
                          <>
                            <HourglassEmpty className="compression-icon" />
                            <h3>Laden...</h3>
                          </>
                        )}
                        {(isCompressing === 'done' ||
                          isCompressing === 'stop') && (
                          <>
                            <AddPhotoAlternateOutlined />
                            <h3>Dateien durchsuchen...</h3>
                          </>
                        )}
                      </div>
                    )}
                    {isEdited &&
                      (imagePreview || location.homepage?.image) && (
                        <img
                          className="image-preview"
                          src={
                            imagePreview || location.homepage?.image
                          }
                          alt={
                            compressedFile?.name ||
                            location.homepage?.title
                          }
                        />
                      )}
                    {!isEdited && location.homepage?.image ? (
                      <img
                        className="image-preview"
                        src={location.homepage?.image}
                        alt={location.homepage?.title}
                      />
                    ) : (
                      'Bild hinzufügen'
                    )}
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.2,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.2,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="homepage-box"
                style={{ width: '100%' }}
              >
                <h2 style={{marginBottom: 0}}>Galerie</h2>
                <p style={{ marginBottom: '2rem', opacity: 0.6 }}>
                  Bitte füge den Instagram API Key hinzu, damit die Bildergalerie verbunden werden kann.
                </p>
                {isEdited && <input type="password" placeholder="Instagram API key..." defaultValue={location.locationInstApi} onChange={e => {
                  handleUpdateLocation('locationInstApi', e.target.value);
                }}/>}
                <span>{location.locationInstApi ? "Deine Galerie scheint zu funktionieren 😍" : "Du hast noch keinen Instagram API Key hinzugefügt 🤷‍♂️" }</span>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.6,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.5,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="adress-box"
              >
                <h2>Adresse</h2>
                <div className="flex-box flex-column">
                  {location.address &&
                    _.map(ordered(location.address), (val, key) => {
                      return (
                        <div key={key} className={key}>
                          <p>
                            {key === 'street'
                              ? 'Straße'
                              : key === 'post-code'
                              ? 'PLZ'
                              : key === 'city'
                              ? 'Ort'
                              : key === 'street-number' &&
                                'Hausnummer'}
                          </p>
                          {!isEdited && (
                            <span>
                              {val
                                ? val
                                : key === 'street'
                                ? 'Straße eintragen'
                                : key === 'post-code'
                                ? 'PLZ eintragen'
                                : key === 'city'
                                ? 'Ort eintragen'
                                : key === 'street-number' &&
                                  'Hausnummer eintragen'}
                            </span>
                          )}
                          {isEdited && (
                            <input
                              type="text"
                              defaultValue={val ? val : ''}
                              placeholder={
                                !val
                                  ? key === 'street'
                                    ? 'Straße eintragen'
                                    : key === 'post-code'
                                    ? 'PLZ eintragen'
                                    : key === 'city'
                                    ? 'Ort eintragen'
                                    : key === 'street-number' &&
                                      'Hausnummer eintragen'
                                  : ''
                              }
                              disabled={!isEdited}
                              className={classnames({
                                disabled: !isEdited,
                              })}
                              onChange={(e) =>
                                handleUpdateLocation('address', {
                                  ...locationsState[activeLocation]
                                    .address,
                                  [key]: e.target.value,
                                })
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                </div>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.8,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.6,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="social-media-box"
              >
                <h2>Social Media</h2>
                <div className="flex-box flex-column">
                  {location.socialMedia &&
                    _.map(
                      ordered(location.socialMedia),
                      (val, key) => {
                        return (
                          <div key={key} className={key}>
                            <div className="flex-box">
                              <p>
                                {key === 'youtube' ? 'YouTube' : key}
                              </p>
                              <div
                                className={classnames('checkbox', {
                                  checked: val.active,
                                  disabled: !isEdited,
                                })}
                                onClick={() =>
                                  handleUpdateLocation(
                                    'socialMedia',
                                    {
                                      ...locationsState[
                                        activeLocation
                                      ].socialMedia,
                                      [key]: {
                                        ...locationsState[
                                          activeLocation
                                        ].socialMedia[key],
                                        active:
                                          !locationsState[
                                            activeLocation
                                          ].socialMedia[key].active,
                                      },
                                    }
                                  )
                                }
                              >
                                <span />
                              </div>
                            </div>
                            {!isEdited && (
                              <span>
                                {val.url
                                  ? val.url
                                  : (key === 'youtube'
                                      ? 'YouTube'
                                      : key.charAt(0).toUpperCase() +
                                        key.slice(1)) + ' Link'}
                              </span>
                            )}
                            {isEdited && (
                              <input
                                type="text"
                                defaultValue={val.url ? val.url : ''}
                                placeholder={
                                  !val.url
                                    ? (key === 'youtube'
                                        ? 'YouTube'
                                        : key
                                            .charAt(0)
                                            .toUpperCase() +
                                          key.slice(1)) + ' Link'
                                    : ''
                                }
                                disabled={!isEdited}
                                className={classnames({
                                  disabled: !isEdited,
                                })}
                                onChange={(e) =>
                                  handleUpdateLocation(
                                    'socialMedia',
                                    {
                                      ...locationsState[
                                        activeLocation
                                      ].socialMedia,
                                      [key]: {
                                        ...locationsState[
                                          activeLocation
                                        ].socialMedia[key],
                                        url: e.target.value,
                                      },
                                    }
                                  )
                                }
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
              </motion.div>
              <motion.div
                initial={{ y: '50px', opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.8,
                    ease: 'easeInOut',
                  },
                }}
                exit={{
                  y: '-100vh',
                  opacity: 0,
                  transition: {
                    delay: 0.6,
                    duration: 1,
                    ease: 'easeInOut',
                  },
                }}
                className="open-times-box"
              >
                <h2>Öffnungszeiten</h2>
                {isEdited && (
                  <DayPickerWidget
                    isEdited={isEdited}
                    data={location.openHours}
                    callback={(values) =>
                      handleUpdateLocation('openHours', values)
                    }
                  />
                )}
                {!isEdited && (
                  <div className="open-hours-container">
                    {location.openHours ? (
                      _.map(location.openHours, (data, index) => (
                        <div className="open-card" key={index}>
                          <h4>{data.title}</h4>
                          <p className="days">
                            {_.map(
                              _.filter(
                                Array.from(
                                  sortDays(Object.keys(data.days))
                                ),
                                (op) => data.days[op].index === index
                              ),
                              (v) => v.slice(0, 2)
                            ).join(', ')}
                          </p>
                          {data.allDayTime && (
                            <div>
                              <p className="bold">
                                Durchgehend geöffnet
                              </p>
                              <p>
                                {data.times.from} - {data.times.to}
                              </p>
                            </div>
                          )}
                          {!data.allDayTime && (
                            <div>
                              <label>
                                <p className="bold">Vormittag</p>
                                <p>
                                  {data.times.morning.from} -{' '}
                                  {data.times.morning.to}
                                </p>
                              </label>
                              <label>
                                <p className="bold">Nachmittag</p>
                                <p>
                                  {data.times.afternoon.from} -{' '}
                                  {data.times.afternoon.to}
                                </p>
                              </label>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>Keine Öffnungszeiten vorhanden</p>
                    )}
                  </div>
                )}
              </motion.div>
            </div>
          ))
        ) : (
          <h2>Bitte, fügen Sie ein Standort hinzu.</h2>
        )}
      </div>
    </section>
  ) : (
    <section id="settings">Laden...</section>
  );
};

export default SettingsComponent;
