import { firestore, storage } from '../firebase';

export const addNewEmployee = async (employeeData) => {
  try {
    if (employeeData.image) {
      await storage
        .ref('employees-images/' + employeeData.image.name)
        .put(employeeData.image)
        .then((val) => {
          val.ref.getDownloadURL().then((imageUrl) => {
            firestore
              .collection('employees')
              .doc(
                employeeData.name.split(' ').join('-').toLowerCase() +
                  '-' +
                  employeeData.surname
                    .split(' ')
                    .join('-')
                    .toLowerCase()
              )
              .set({
                name: employeeData.name,
                surname: employeeData.surname,
                description: employeeData.description,
                createdAt: new Date(),
                imageUrl: imageUrl,
                imageName: employeeData.image.name,
              })
              .then(() =>
                window.location.replace(
                  process.env.REACT_APP_DOMAIN_URL + 'team'
                )
              );
          });
        });
    }
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};
export const getAllEmployees = async () => {
  try {
    return await firestore
      .collection('employees')
      .get()
      .then((snap) => {
        const employees = [];
        snap.forEach((doc) => {
          employees.push({
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
          });
        });
        return employees;
      });
  } catch (err) {
    alert(err.message);
  }
};
export const deleteEmployee = async (employeeName, imageName) => {
  try {
    return await firestore
      .collection('employees')
      .doc(employeeName)
      .delete()
      .then(() => {
        storage
          .ref('employees-images/')
          .child(imageName)
          .delete()
          .then(() =>
            window.location.replace(
              process.env.REACT_APP_DOMAIN_URL + 'team'
            )
          );
      });
  } catch (err) {
    alert(err.message);
  }
};

export const updateEmployee = async (employeeData, oldData) => {
  const oldDataTitle = oldData.oldEmpId
    ? oldData.oldEmpId.split(' ').join('-').toLowerCase()
    : false;
  const newDataTitle =
    employeeData.name.split(' ').join('-').toLowerCase() +
    '-' +
    employeeData.surname.split(' ').join('-').toLowerCase();

  try {
    if (oldData.deleteEmp) {
      await firestore
        .collection('employees')
        .doc(oldDataTitle)
        .delete()
        .then(() => {
          if (oldData.imageChanged) {
            storage
              .ref('employees-images/')
              .child(oldData.oldEmpImageName)
              .delete()
              .then(() => {
                storage
                  .ref('employees-images/' + employeeData.image.name)
                  .put(employeeData.image)
                  .then((val) => {
                    val.ref.getDownloadURL().then((imageUrl) => {
                      firestore
                        .collection('employees')
                        .doc(newDataTitle)
                        .set({
                          name: employeeData.name,
                          surname: employeeData.surname,
                          description: employeeData.description,
                          createdAt: employeeData.createdAt,
                          imageUrl: imageUrl,
                          imageName: employeeData.image.name,
                        })
                        .then(() =>
                          window.location.replace(
                            process.env.REACT_APP_DOMAIN_URL + 'team'
                          )
                        );
                    });
                  });
              });
          } else {
            firestore
              .collection('employees')
              .doc(newDataTitle)
              .set({
                ...employeeData,
              })
              .then(() =>
                window.location.replace(
                  process.env.REACT_APP_DOMAIN_URL + 'team'
                )
              );
          }
        });
    } else {
      if (oldData.imageChanged) {
        function onResolve(foundURL) {
          storage
            .ref('employees-images/')
            .child(oldData.oldEmpImageName)
            .delete()
            .then(() => {
              storage
                .ref('employees-images/' + employeeData.image.name)
                .put(employeeData.image)
                .then((val) => {
                  val.ref.getDownloadURL().then((imageUrl) => {
                    firestore
                      .collection('employees')
                      .doc(newDataTitle)
                      .update({
                        name: employeeData.name,
                        surname: employeeData.surname,
                        description: employeeData.description,
                        createdAt: employeeData.createdAt,
                        imageUrl: imageUrl,
                        imageName: employeeData.image.name,
                      })
                      .then(() =>
                        window.location.replace(
                          process.env.REACT_APP_DOMAIN_URL + 'team'
                        )
                      );
                  });
                });
            });
        }

        function onReject(error) {
          storage
            .ref('employees-images/' + employeeData.image.name)
            .put(employeeData.image)
            .then((val) => {
              val.ref.getDownloadURL().then((imageUrl) => {
                firestore
                  .collection('employees')
                  .doc(newDataTitle)
                  .update({
                    name: employeeData.name,
                    surname: employeeData.surname,
                    description: employeeData.description,
                    createdAt: employeeData.createdAt,
                    imageUrl: imageUrl,
                    imageName: employeeData.image.name,
                  })
                  .then(() =>
                    window.location.replace(
                      process.env.REACT_APP_DOMAIN_URL + 'team'
                    )
                  );
              });
            });
        }
        storage
          .ref('employees-images/')
          .child(oldData.oldEmpImageName)
          .getDownloadURL()
          .then(onResolve, onReject);
      } else {
        firestore
          .collection('employees')
          .doc(newDataTitle)
          .update({
            ...employeeData,
          })
          .then(() =>
            window.location.replace(
              process.env.REACT_APP_DOMAIN_URL + 'team'
            )
          );
      }
    }
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};
