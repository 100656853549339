import React from 'react';
import {Link} from 'react-router-dom';

const SubFooterComponent = ({title, btnText}) => {
    return <div className="sub-footer">
        <h2>{title}</h2>
        <Link to={'/contact'} className="btn-primary-dark">{btnText}</Link>
    </div>
}

export default SubFooterComponent;