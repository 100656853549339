import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { default as ArrowDownIcon } from '../../assets/icons/arrow-down.png';
import heroImage from '../../assets/images/otra-vida-tattoo_hero-image.webp';
import { services, principles } from '../../utils/home.sections.data';
import * as _ from 'lodash';
import SectionWidget from '../../widgets/section.widget';
import AbilitiesWidget from '../../widgets/abilities.widget';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import anime from 'animejs';
import { useAuth } from '../../contexts/auth.context';

const HomeComponent = () => {
    const {locations} = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: {
      opacity: 0,
      x: '-100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        delay: 0,
      },
    },
    exit: {
      x: '-100vw',
      opacity: 0,
      transition: {
        ease: 'easeInOut',
        delay: 0,
        duration: 1,
      },
    },
  };

  return (
    <motion.main
      id="home"
      variants={containerVariants}
      initial="hidden"
      exit="exit"
      animate="visible"
    >
      <Helmet>
        <meta
          name="description"
          content="Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte"
        />
        <meta
          name="keywords"
          content="Tattoo, Piercing, Stuttgart, Stuttgart-Mitte, City, Termine, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort"
        />
        <meta
          property="og:title"
          content="Home – Otra Vida Tattoo & Piercing Stuttgart"
        />
        <meta
          property="og:description"
          content="Professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte"
        />
        <meta
          property="og:image"
          content="/assets/images/otra-vida-tattoo_hero-image.png"
        />
        <title>Home – Otra Vida Tattoo & Piercing Stuttgart</title>
      </Helmet>
      <section id="hero">
        <motion.h1 className="section-title">
          Professionelle Tattoos und Piercings in {locations && locations.map((location, index) => `${location.locationName} ${locations.length - 1 === index ? "" : "und "} `)}.
        </motion.h1>
        <motion.p className="section-description">
          Bei uns kommst du ohne lange Wartezeiten schnell zu deinem
          professionellen Tattoo oder Piercing. Wir beraten Dich
          persönlich und gehen auf Deine Wünsche und Ideen ein. Dabei
          halten wir höchste Hygiene- und Sicherheitsstandards ein,
          damit du dich rundum bei uns wohlfühlst.
        </motion.p>
        <Link to="/contact" className="btn-secondary">
          Jetzt Termin vereinbaren
        </Link>
        <img
          src={ArrowDownIcon}
          alt="Go down icon"
          className="arrow-down"
          onClick={(e) => {
            const scrollElement =
              window.document.scrollingElement ||
              window.document.body ||
              window.document.documentElement;
            anime({
              targets: scrollElement,
              scrollTop:
                document.getElementById('services').offsetTop - 80,
              easing: 'easeInQuad',
              duration: 1500,
            });
          }}
        />
        <motion.img className="section-image" src={heroImage} />
      </section>
      <h3 className="pre-title" id="services">
        Services
      </h3>
      {_.map(services, (service, key) => {
        return (
          <SectionWidget
            key={key}
            title={service.title}
            subTitle={service.subTitle}
            description={service.description}
            btnName={service.btnName}
            linkTo={service.linkTo}
            imgPath={service.image}
            sectionName={service.sectionName}
            n={key}
          />
        );
      })}
      {locations &&
        _.map(
          locations,
          (location, key) =>
            location.locationName &&
            location.title &&
            location.description &&
            location.image && (
              <SectionWidget
                key={key}
                title={location.locationName}
                subTitle={location.title}
                description={location.description}
                btnName={`Jetzt Termin in ${location.locationName} vereinbaren`}
                imgPath={location.image}
                sectionName={'Standorte'}
                linkTo={`contact/${location.locationName}`}
                n={key}
              />
            )
        )}
      <h3 className="pre-title">Prinzipien</h3>
      <section className="section-widget" id="principles">
        <h2 className="section-title">Das macht uns einzigartig</h2>
        <div className="abilities-box">
          {_.map(principles, (principle, key) => (
            <AbilitiesWidget
              key={key}
              title={principle.title}
              description={principle.description}
              icon={principle.icon}
            />
          ))}
        </div>
      </section>
    </motion.main>
  );
};

export default HomeComponent;
