import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { getAllEmployees } from '../../utils/database/employee';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import EmployeeWidget from '../../widgets/employee.widget';
import {motion} from 'framer-motion';
import {Helmet} from 'react-helmet-async';

const TeamComponent = () => {
    const [team, setTeam] = useState(null);
    const {currentUser} = useAuth();

    const containerVariants = {
        hidden: {
            opacity: 0,
            x: '100vw',
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
                delay: 0,
            }
        },
        exit: {
            y: '100vh',
            opacity: 0,
            transition: {
                ease: 'easeInOut',
                delay: 0,
                duration: 1,
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllEmployees().then(data => {
            if (!_.isEmpty(data)) {
                setTeam(data);
            }
        });
    }, []);

    return <motion.section variants={containerVariants} initial="hidden" animate="visible" exit="exit" id="team">
        <Helmet>
            <meta name="description" content="Das Team für professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitteg" />
            <meta name="keywords" content="Tattoo, Piercing, Stuttgart, Stuttgart-Mitte, Team, Staff, Mitarbeiter, City, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort" />
            <meta property="og:title" content="Team – Otra Vida Tattoo & Piercing Stuttgart" />
            <meta property="og:description" content="Das Team für professionelle Tattoos, Piercings, Haare & Make-up  in Stuttgart-Mitte" />
            <meta property="og:image" content="/assets/images/otra-vida_fb-image.png"/>
            <title>Team – Otra Vida Tattoo & Piercing Stuttgart</title>
        </Helmet>
        <div className="section-header">
            <h1>Team</h1>
            {currentUser && <Link className="btn-primary" to="/new-employee">Mitarbeiter hinzufügen</Link>}
        </div>
        <div className="section-body">
            {team ? _.map(_.sortBy(team, 'createdAt'), (employee, key) => {
                return <EmployeeWidget
                    key={key}
                    n={key}
                    name={employee.name}
                    surname={employee.surname}
                    description={employee.description}
                    imgPath={employee.imageUrl}
                    imgName={employee.imageName}
                    createdAt={employee.createdAt}
                />
            }) : <div className="information-about-login">
                <h2>😳 Es gibt leider keine Mitarbeiter</h2>
            </div>}
        </div>
    </motion.section>
}

export default TeamComponent;