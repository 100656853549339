import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { AddPhotoAlternateOutlined, HourglassEmpty, KeyboardArrowLeft } from '@material-ui/icons';
import TagInputWidget from '../../widgets/tag.input.widget';
import { useHistory } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import * as _ from 'lodash';
import { addNewPost } from '../../utils/database/posts';
import {motion} from 'framer-motion';
import Compressor from 'compressorjs';
import {v4} from 'uuid';
import {Helmet} from 'react-helmet-async';

const NewPostComponent = () => {
    const history = useHistory();
    const [imagePreview, setImagePreview] = useState();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState();
    const [available, setAvailable] = useState(false);
    const [compressedFile, setCompressedFile] = useState(null);
    const [isCompressing, setIsCompressing] = useState('stop');

    const containerVariants = {
        hidden: {
            opacity: 0,
            x: '-100vw',
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
                delay: 0,
            }
        },
        exit: {
            y: '100vh',
            opacity: 0,
            transition: {
                ease: 'easeInOut',
                delay: 0,
                duration: 1,
            }
        }
    }

    const onDrop = (pictureFile, pictureDataURL) => {
        const image = pictureFile && pictureFile[0] && pictureFile[0].type === 'image/svg+xml' ? new Blob(pictureFile, {type: 'image/svg+xml'}) : new Blob(pictureFile,{type: 'image/jpeg'});
        setIsCompressing('start');
        new Compressor(image, {
            mimeType: 'image/webp',
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            checkOrientation: false,
            success: (compressedResult) => {
                compressedResult.name = v4() + '.webp';
                setCompressedFile(compressedResult);
                setImagePreview(URL.createObjectURL(compressedResult))
                setIsCompressing('done');
            },
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title && description && !_.isEmpty(compressedFile)) {
            addNewPost({
                title,
                description,
                'tags': _.isEmpty(tags) ? false : tags,
                image: compressedFile
            });
        }
    }

    useEffect(() => {
        if (title && description && !_.isEmpty(compressedFile)) {
            setAvailable(true);
        }
    }, [title, compressedFile, description, tags]);

    return <motion.section variants={containerVariants} initial="hidden" exit="exit" animate="visible" id="new-post">
        <Helmet>
            <meta name="description" content="Stellen Sie ein neue Beitrag auf Neews seite" />
            <meta property="og:description" content="Stellen Sie ein neue Beitrag auf Neews seite" />
            <meta name="keywords" content="Tattoo, Piercing, Stuttgart, News, Neuigkeiten, Nachrichten, Blog, Stuttgart-Mitte, City, Termine, kurze Wartezeit, faire Preise, Franco Farao, Walk in, Termine online, Professionell, Profi, Tätowierer, Piercer, Make-up, Hair, Stylist, Schminken, Lippenstift, Wimpern, Augen, All Sexes, Tunnels, Tattoo Artist, Tübinger Strasse, Höchste Hygienestandards, Hygiene, Sicherheit, Corona-konform, Steril, Alle Stile, Cover-up, Flashes, Wannado, Intimpiercing, Qualität, Wohlfühlen, Komfort" />
            <meta property="og:title" content="Neue Beitrag - Otra Vida Tattoo & Piercing Stuttgart" />
            <meta property="og:image" content="/assets/images/otra-vida_fb-image.png"/>
            <title>Neue Beitrag - Otra Vida Tattoo & Piercing Stuttgart</title>
        </Helmet>
        <div className="section-header">
            <KeyboardArrowLeft className="btn-back" onClick={() => history.push('/news')}/>
            <div className="btn-grouping">
                <button className={classnames("btn-primary", {'disabled': !available})} onClick={handleSubmit}>Veröffentlichen</button>
                <button className="btn-secondary" onClick={() => history.push('/news')}>Abbrechen</button>
            </div>
        </div>
        <div className="section-body">
            <input className="post-title" placeholder="Neuer Beitrag" type="text" name="title" onChange={e => setTitle(e.target.value)}/>
            <div className="flex-box">
                <div className="image-box">
                    <ImageUploader maxFileSize={13631488} withIcon={false} buttonText={''} imgExtension={['.jpg', '.png', '.jpeg', '.svg']} singleImage={true} onChange={onDrop} fileTypeError='Ungültiger Dateityp' fileSizeError='Ungültige Dateigröße'/>
                    <div className="uploader-info">
                        {isCompressing === 'start' && <>
                            <HourglassEmpty className="compression-icon"/>
                            <h3>Laden...</h3>
                        </>}
                        {(isCompressing === 'done' || isCompressing === 'stop') && <>
                            <AddPhotoAlternateOutlined/>
                            <h3>Dateien durchsuchen...</h3>
                        </>}
                    </div>
                    {imagePreview && <img className="image-preview" src={imagePreview} alt={compressedFile.name}/>}
                </div>
                <textarea type="text" className="post-description" name="description" placeholder="Beschreibung hinzufügen..." onChange={e => setDescription(e.target.value)}/>
            </div>
            <label>
                <p>Tags</p>
                <TagInputWidget onInputChange={t => setTags(t)}/>
            </label>
        </div>
    </motion.section>
}

export default NewPostComponent;