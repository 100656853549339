import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../utils/firebase';
import firebase from 'firebase';
import { getAllHomepageLocations } from '../utils/database/settings';

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [settings, setSettings] = useState(null);
  const [locations, setLocations] = useState(null);

  function signIn(email, password) {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return auth
          .signInWithEmailAndPassword(email, password)
          .then((res) => res)
          .catch((err) => err);
      });
  }

  function signOut() {
    return auth
      .signOut()
      .then(
        () => (window.location = process.env.REACT_APP_DOMAIN_URL)
      );
  }

  const updateState = async () => {

    return firestore
      .collection('settings')
      .orderBy('createdAt')
      .get()
      .then(
        (snap) => {
          setSettings(null);
          snap.forEach((doc) =>
            doc.exists && doc.data()
              ? setSettings((old) => ({
                  ...old,
                  [doc.id]: doc.data(),
                }))
              : false
          )
          getAllHomepageLocations().then((result) => setLocations(result));
        }
      );
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        updateState();
      }
      getAllHomepageLocations().then((result) => setLocations(result));
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    settings,
    signIn,
    updateState,
    signOut,
    locations,
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
